/* eslint no-console: 0 */
import * as types from '../../../mutation-types';
import IncomingMessageList from '../../../../api/reports/message/IncomingMessageList';

const state = {
  isFetching: false,
  messages: {
    data: [],
    meta: {},
  },
  download: {
    isLoading: false,
  },
};

const getters = {
  getMessageIncoming(_state) {
    return _state.messages;
  },
  isLoading(_state) {
    return _state.isFetching;
  },
  getDownloaded(_state) {
    return _state.download.isLoading;
  },
};

export const actions = {
  get: async function getMessageIncoming({ commit }, params) {
    commit(types.default.TOGGLE_MESSAGE_INCOMING, { isFetching: true });
    try {
      const response = await IncomingMessageList.get(params);
      commit(types.default.SET_MESSAGE_INCOMING, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.default.TOGGLE_MESSAGE_INCOMING, { isFetching: false });
    }
  },
  download: async function downloadMessageIncoming({ commit }, params) {
    commit(types.default.TOGGLE_DOWNLOAD_MESSAGE_INCOMING, {
      isFetching: true,
    });
    try {
      const response = await IncomingMessageList.download(params);
      commit(types.default.SET_DOWNLOAD_MESSAGE_INCOMING, response);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.default.TOGGLE_DOWNLOAD_MESSAGE_INCOMING, {
        isFetching: false,
      });
    }
  },
};

const mutations = {
  [types.default.SET_MESSAGE_INCOMING](_state, IncomingMessages) {
    _state.messages.data = IncomingMessages.payload;
    _state.messages.meta = IncomingMessages.meta;
  },
  [types.default.TOGGLE_MESSAGE_INCOMING](_state, flag) {
    _state.isFetching = flag.isFetching;
  },

  [types.default.SET_DOWNLOAD_MESSAGE_INCOMING](_state, download, flag) {
    if (
      download.status === Number(200) &&
      download.statusText === String('OK')
    ) {
      _state.download.isLoading = flag.isFetching;
    }
  },
  [types.default.TOGGLE_DOWNLOAD_MESSAGE_INCOMING](_state, flag) {
    _state.download.isLoading = flag.isFetching;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
