import types from '../../mutation-types';
import TemplatesAPI from '../../../api/workflowTemplates/templates.js';

export const state = {
  records: [],
  singleTemplate: {},
  uiFlags: {
    isFetching: false,
  },
};

export const getters = {
  getTemplates(_state) {
    return _state.records;
  },
  getUIFlag(_state) {
    return _state.uiFlags;
  },
  getSingleTemplate: _state => {
    return _state.singleTemplate || {};
  },
};

export const actions = {
  get: async function getTemplates({ commit }) {
    commit(types.SET_WORKFLOW_TEMPLATES_UI_FLAG, { isFetching: true });
    try {
      const response = await TemplatesAPI.get();
      commit(types.SET_WORKFLOW_TEMPLATES, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_WORKFLOW_TEMPLATES_UI_FLAG, {
        isFetching: false,
      });
    }
  },
  create: async function ({ commit }, template) {
    // commit(types.SET_WORKFLOW_TEMPLATES_UI_FLAG, { isCreating: true });
    try {
      const response = await TemplatesAPI.create(template.id, template);
      // commit(types.SET_WORKFLOW_TEMPLATES, response.data);
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      // commit(types.SET_WORKFLOW_TEMPLATES_UI_FLAG, { isCreating: false });
    }
  },

  getSingleTemplate: async function getTemplate({ commit }, id) {
    commit(types.SET_WORKFLOW_TEMPLATES_UI_FLAG, { isFetching: true });
    try {
      const response = await TemplatesAPI.show(id);
      commit(types.SET_WORKFLOW_SINGLE_TEMPLATE, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_WORKFLOW_TEMPLATES_UI_FLAG, {
        isFetching: false,
      });
    }
  },
};

export const mutations = {
  [types.SET_WORKFLOW_TEMPLATES_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
  [types.SET_WORKFLOW_TEMPLATES](_state, data) {
    _state.records = data;
  },
  [types.SET_WORKFLOW_SINGLE_TEMPLATE](_state, data) {
    _state.singleTemplate = data;
  },
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
