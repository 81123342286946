/* global axios */
import ApiClient from './ApiClient';

class ConversationLogsAPI extends ApiClient {
  constructor() {
    super('conversation_logs', { accountScoped: true, apiVersion: 'v3' });
  }

  getConversationLogs(params) {
    return axios.get(`${this.url}`, {
      params: {
        since: params.since,
        until: params.until,
        page: params.page,
        note_id: params.notes,
        agent_id: params.agents,
        team_id: params.teams,
        sort: params.sort?.order_column,
        direction: params.sort?.order_direction,
        search: params.search,
      },
    });
  }
}

export default new ConversationLogsAPI();
