import types from '../mutation-types';
import ContactsReports from '../../api/contactsReports';

export const state = {
  logs_summary: {
    data: {},
    previous: {},
  },
  added_contact_logs_by_source_chart: {},
  added_contact_logs_summary: {},
  contact_logs_chart: {},
  contact_logs_table: {},
  uiFlags: {
    isFetchingLogsSummary: false,
    isFetchingContactsLogsSummaryChart: false,
    isFetchingAddedLogsChart: false,
    isFetchingTableData: false,
    isFetchingContactsLogsCount: false,
  },
};

export const getters = {
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  getLogsSummary(_state) {
    return _state.logs_summary;
  },
  getAddedContactsLogsChart(_state) {
    return _state.added_contact_logs_by_source_chart;
  },
  getContactsLogsChart(_state) {
    return _state.contact_logs_chart;
  },
  getContactsLogsTableData(_state) {
    return _state.contact_logs_table;
  },
  getContactsLogsCount(_state) {
    return _state.added_contact_logs_summary;
  },
};
export const actions = {
  async getLogsSummary({ commit }, params) {
    commit(types.SET_CONTACTS_REPORTS_UI_FLAG, { isFetchingLogsSummary: true });
    try {
      const { data } = await ContactsReports.getLogsSummary(params);
      commit(types.SET_CONTACT_REPORTS_LOGS_SUMMARY, data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CONTACTS_REPORTS_UI_FLAG, {
        isFetchingLogsSummary: false,
      });
    }
  },
  async getContactsLogsChart({ commit }, params) {
    commit(types.SET_CONTACTS_REPORTS_UI_FLAG, {
      isFetchingContactsLogsSummaryChart: true,
    });
    try {
      const { data } = await ContactsReports.getContactsLogsChart(params);
      commit(types.SET_CONTACT_REPORTS_CONTACT_LOGS_CHART, data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CONTACTS_REPORTS_UI_FLAG, {
        isFetchingContactsLogsSummaryChart: false,
      });
    }
  },
  async getAddedContactsLogsChart({ commit }, params) {
    commit(types.SET_CONTACTS_REPORTS_UI_FLAG, {
      isFetchingAddedLogsChart: true,
    });
    try {
      const { data } = await ContactsReports.getAddedContactsLogsChart(params);
      commit(types.SET_CONTACT_REPORTS_ADDED_CONTACT_LOGS_CHART, data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CONTACTS_REPORTS_UI_FLAG, {
        isFetchingAddedLogsChart: false,
      });
    }
  },
  async getContactsLogsTableData({ commit }, params) {
    commit(types.SET_CONTACTS_REPORTS_UI_FLAG, {
      isFetchingTableData: true,
    });
    try {
      const { data } = await ContactsReports.getContactsLogsTableData(params);
      commit(types.SET_CONTACT_REPORTS_TABLE_DATA, data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CONTACTS_REPORTS_UI_FLAG, {
        isFetchingTableData: false,
      });
    }
  },
  async getContactsLogsCount({ commit }, params) {
    commit(types.SET_CONTACTS_REPORTS_UI_FLAG, {
      isFetchingContactsLogsCount: true,
    });
    try {
      const { data } = await ContactsReports.getContactsLogsCount(params);
      commit(types.SET_CONTACT_REPORTS_CONTACT_LOGS_COUNT, data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CONTACTS_REPORTS_UI_FLAG, {
        isFetchingContactsLogsCount: false,
      });
    }
  },
};

export const mutations = {
  [types.SET_CONTACTS_REPORTS_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
  [types.SET_CONTACT_REPORTS_LOGS_SUMMARY](_state, data) {
    _state.logs_summary.data = { ...data };
    delete _state.logs_summary.data.previous;
    _state.logs_summary.previous = { ...data.previous };
  },
  [types.SET_CONTACT_REPORTS_CONTACT_LOGS_CHART](_state, data) {
    _state.contact_logs_chart = { ...data };
  },
  [types.SET_CONTACT_REPORTS_ADDED_CONTACT_LOGS_CHART](_state, data) {
    _state.added_contact_logs_by_source_chart = { ...data };
  },
  [types.SET_CONTACT_REPORTS_TABLE_DATA](_state, data) {
    _state.contact_logs_table = { ...data };
  },
  [types.SET_CONTACT_REPORTS_CONTACT_LOGS_COUNT](_state, data) {
    _state.added_contact_logs_summary = { ...data };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
