import { frontendURL } from '../../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/subscription'),
      roles: ['administrator'],
      component: () => import('../Wrapper'),
      props: {
        headerTitle: 'SIDEBAR.SUBSCRIPTION',
        icon: 'subscription',
        showNewButton: false,
      },
      children: [
        {
          path: '',
          component: () => import('./Index.vue'),
          name: 'subscription_settings_index',
        },
      ],
    },
  ],
};