<template>
  <mention-box :items="items" @mention-select="handleVariableClick">
    <template slot-scope="{ item }">
      <span class="text-capitalize variable--list-label">
        {{ item.description }}
      </span>
      ({{ item.label }})
    </template>
  </mention-box>
</template>

<script>
import { MESSAGE_VARIABLES } from 'shared/constants/messages';
import MentionBox from '../mentions/MentionBox.vue';
import attributeMixin from 'dashboard/mixins/attributeMixin';

export default {
  components: { MentionBox },
  mixins: [attributeMixin],

  props: {
    searchKey: {
      type: String,
      default: '',
    },
    hasAssigned: {
      type: Array,
      default: [],
    },
  },
  computed: {
    convertAttributes() {
      let attributes = [];
      for (let i = 0; i < this.filteredAttributes.length; i++) {
        const attr = this.filteredAttributes[i];
        const obj = {
          label: attr.attribute_display_name,
          key: `contact.${attr.attribute_key}`,
        };
        attributes.push(obj);
      }
      return attributes;
    },
    items() {
      return [
        ...MESSAGE_VARIABLES,
        ...this.hasAssigned,
        ...this.convertAttributes,
      ]
        .filter(variable => {
          return (
            variable.label.includes(this.searchKey) ||
            variable.key.includes(this.searchKey)
          );
        })
        .map(variable => ({
          label: variable.key,
          key: variable.key,
          description: variable.label,
        }));
    },
  },
  methods: {
    handleVariableClick(item = {}) {
      this.$emit('click', item.key);
    },
  },
};
</script>
<style scoped>
.variable--list-label {
  font-weight: var(--font-weight-bold);
}
</style>
