import { frontendURL } from '../../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/campaigns'),
      component: () => import('../Wrapper.vue'),
      props: {
        headerTitle: 'CAMPAIGN.WHATSAPP.HEADER',
        icon: 'whatsapp',
      },
      children: [
        {
          path: '',
          redirect: 'whatsapp',
        },
        {
          path: 'whatsapp',
          name: 'whatsapp',
          roles: ['administrator'],
          component: () => import('./whatsapp/Index'),
          meta: {
            permission: 'show_campaigns',
          },
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/campaigns'),
      component: () => import('../Wrapper.vue'),
      props: {
        headerTitle: 'CAMPAIGN.ONGOING.HEADER',
        icon: 'arrow-swap',
      },
      children: [
        {
          path: 'ongoing',
          name: 'settings_account_campaigns',
          roles: ['administrator'],
          component: () => import('./Index'),
          meta: {
            permission: 'show_campaigns',
          },
        },
      ],
    },
  ],
};
