import ApiClient from './ApiClient';

class BevaAI extends ApiClient {
  constructor() {
    super('knowledge_bases', { accountScoped: true });
  }

  getKnowledgeSources(params) {
    return axios.get(`${this.url}`, {
      params: {
        page: params.page,
        search: params.search,
        limit: params?.limit,
      },
    });
  }

  getCompletedKnowledgeSources(params) {
    return axios.get(`${this.url}/completed`, {
      params: {
        limit: params?.limit,
      },
    });
  }
}

export default new BevaAI();
