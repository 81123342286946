<template>
  <div class="column content-box">
    <div class="center">
      <div>
        <img
          class="setting-image"
          src="~dashboard/assets/images/setting.png"
          alt="NO PERMEATIONS"
        />
        <h5 class="mt-4">{{ $t('PROFILE_SETTINGS.NO_PERMEATIONS') }}</h5>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.setting-image {
  max-width: 25rem;
  object-fit: contain;
  display: block;
  margin: auto;
}
</style>
