import { frontendURL } from '../../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/teams'),
      component: () => import('../Wrapper'),
      props: params => {
        const showBackButton = params.name !== 'settings_teams_list';
        return {
          permission: 'create_teams',
          headerTitle: 'TEAMS_SETTINGS.HEADER',
          icon: 'people-team',
          showBackButton,
        };
      },
      children: [
        {
          path: '',
          name: 'settings_teams',
          redirect: 'list',
        },
        {
          path: 'list',
          name: 'settings_teams_list',
          component: () => import('./Index'),
          roles: ['administrator'],
          meta: {
            permission: 'show_teams',
          },
        },
        {
          path: 'new',
          component: () => import('./Create/Index'),
          children: [
            {
              path: '',
              name: 'settings_teams_new',
              component: () => import('./Create/CreateTeam'),
              roles: ['administrator'],
              meta: {
                permission: 'create_teams',
              },
            },
            {
              path: ':teamId/finish',
              name: 'settings_teams_finish',
              component: () => import('./FinishSetup'),
              roles: ['administrator'],
              meta: {
                permission: 'create_teams',
              },
            },
            {
              path: ':teamId/agents',
              name: 'settings_teams_add_agents',
              roles: ['administrator'],
              component: () => import('./Create/AddAgents'),
              meta: {
                permission: 'create_teams',
              },
            },
          ],
        },
        {
          path: ':teamId/edit',
          component: () => import('./Edit/Index'),
          children: [
            {
              path: '',
              name: 'settings_teams_edit',
              component: () => import('./Edit/EditTeam'),
              roles: ['administrator'],
              meta: {
                permission: 'update_teams',
              },
            },
            {
              path: 'agents',
              name: 'settings_teams_edit_members',
              component: () => import('./Edit/EditAgents'),
              roles: ['administrator'],
              meta: {
                permission: 'update_teams',
              },
            },
            {
              path: 'finish',
              name: 'settings_teams_edit_finish',
              roles: ['administrator'],
              component: () => import('./FinishSetup'),
              meta: {
                permission: 'update_teams',
              },
            },
          ],
        },
      ],
    },
  ],
};
