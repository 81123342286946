<template>
  <transition name="modal-fade">
    <div
      v-if="show"
      :class="modalClassName"
      transition="modal"
      @click="onBackDropClick"
    >
      <div :class="modalContainerClassName" @click.stop>
        <div class="header-top">
          <!-- header text -->
          <woot-modal-header
            :header-title="headerTitle"
            :header-content="headerContent"
            :header-content-value="headerContentValue"
          >
            <slot name="header"></slot>
          </woot-modal-header>
          <!-- close button -->
          <button class="modal__close-btn" @click="close">
            <i class="fa-regular fa-circle-xmark"></i>
          </button>
        </div>

        <div class="modal-content">
          <slot />
        </div>

        <div class="modal-footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    closeOnBackdropClick: {
      type: Boolean,
      default: true,
    },
    show: Boolean,
    onClose: {
      type: Function,
      required: true,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    modalType: {
      type: String,
      default: 'centered',
    },
    size: {
      type: String,
      default: '',
    },
    headerTitle: {
      type: String,
      default: '',
    },
    headerContent: {
      type: String,
      default: '',
    },
    headerContentValue: {
      type: String,
      default: '',
    },
  },
  computed: {
    modalContainerClassName() {
      let className = 'modal-container skip-context-menu';
      if (this.fullWidth) {
        return `${className} modal-container--full-width`;
      }

      return `${className} ${this.size}`;
    },
    modalClassName() {
      const modalClassNameMap = {
        centered: '',
        'right-aligned': 'right-aligned',
      };

      return `modal-mask skip-context-menu ${
        modalClassNameMap[this.modalType] || ''
      }`;
    },
  },
  mounted() {
    document.addEventListener('keydown', e => {
      if (this.show && e.code === 'Escape') {
        this.onClose();
      }
    });
  },
  methods: {
    close() {
      this.onClose();
    },
    onBackDropClick() {
      if (this.closeOnBackdropClick) {
        this.onClose();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.modal-container {
  border-radius: 24px;
  width: 75rem;
  .button.clear {
    color: var(--b-600);
    border: 1.5px solid var(--b-600);
    border-radius: 50%;
  }
  .header-top {
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    border-bottom: 1px solid var(--b-500);
    .modal__close-btn {
      width: 38px;
      height: 38px;
      cursor: pointer;
      font-size: 24px;
      color: var(--b-600);
      background: var(--b-50);
      border-radius: 50%;
    }
  }
  .modal-content {
    padding: 24px;
    height: auto;
    overflow: auto;
  }
  .modal-footer {
    border-top: 1px solid var(--b-500);
    padding: 24px 24px 16px;
    gap: 8px;
  }
}
// special cases
.modal-container--full-width {
  align-items: center;
  border-radius: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.modal-mask.right-aligned {
  justify-content: flex-end;

  .modal-container {
    border-radius: 0;
    height: 100%;
    width: 50rem;
  }
}
.modal-big {
  width: 60%;
}
.no-close-btn {
  .modal__close-btn {
    display: none;
  }
}
.no-content {
  .modal-content {
    padding: 0;
  }
  @extend .no-border;
}
.no-footer {
  .modal-footer {
    border: none;
    padding: 0;
  }
}
.no-border {
  .header-top,
  .modal-footer {
    border: none;
  }
}
.info-modal {
  @extend .no-border;
  @extend .no-footer;
  .header-top {
    display: none;
  }
}
</style>
