<template>
  <div class="preview-card--wrap" :class="{ active: active }">
    <div class="header">
      <input type="radio" name="" id="" :checked="active ? true : false" />
      <div class="header__text">{{ heading }}</div>
    </div>
    <div class="hint">
      {{ content }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    heading: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.preview-card--wrap {
  background-color: var(--s-50);
  border-radius: 8px;
  font-size: 16px;
  font-weight: 400;
  padding: 16px;
  .header {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
    input[type='radio'] {
      height: 24px;
      width: 24px;
      &::before {
        height: 16px;
        width: 16px;
      }
    }
    &__text {
      color: var(--b-900);
    }
  }
  .hint {
    color: var(--b-400);
  }
  &.active {
    border: 1px solid var(--w-500);
  }
}
</style>
