/* global axios */
import ApiClient from '../../ApiClient';
const getTimeOffset = () => -new Date().getTimezoneOffset() / 60;

class MetricsMessages extends ApiClient {
  constructor() {
    super('reports', { accountScoped: true, apiVersion: 'v3' });
  }

  incomingMetric(prams) {
    return axios.get(`${this.url}?metric=incoming_messages`, {
      params: {
        since: prams.since,
        until: prams.until,
        group_by: prams.group_by.period || prams.group_by,
        inbox_id: prams.inbox_id,
        team_id: prams.team_id,
        agent_id: prams.agent_id,
        group_by_type: prams.group_by_type,
        timezone_offset: getTimeOffset(),
      },
    });
  }

  outgoingMetric(prams) {
    return axios.get(`${this.url}?metric=outgoing_messages`, {
      params: {
        since: prams.since,
        until: prams.until,
        group_by: prams.group_by.period || prams.group_by,
        inbox_id: prams.inbox_id,
        team_id: prams.team_id,
        agent_id: prams.agent_id,
        group_by_type: prams.group_by_type,
        timezone_offset: getTimeOffset(),
      },
    });
  }

  messagesSummary(prams) {
    return axios.get(`${this.url}/messages_summary`, {
      params: {
        since: prams.since,
        until: prams.until,
        team_id: prams.team_id,
        agent_id: prams.agent_id,
        inbox_id: prams.inbox_id,
        timezone_offset: getTimeOffset(),
      },
    });
  }
}

export default new MetricsMessages();
