var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.changePassword()}}},[_c('div',{staticClass:"setting-item"},[_c('div',{staticClass:"text"},[_c('h4',{staticClass:"setting-item__text__title"},[_vm._v("\n        "+_vm._s(_vm.$t('PROFILE_SETTINGS.FORM.PASSWORD_SECTION.TITLE'))+"\n      ")]),_vm._v(" "),_c('p',{staticClass:"setting-item__text__help-text"},[_vm._v("\n        "+_vm._s(_vm.$t('PROFILE_SETTINGS.FORM.PASSWORD_SECTION.NOTE'))+"\n      ")])]),_vm._v(" "),_c('div',{staticClass:"my-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('woot-input',{class:{ error: _vm.$v.currentPassword.$error },attrs:{"type":"password","label":_vm.$t('PROFILE_SETTINGS.FORM.CURRENT_PASSWORD.LABEL'),"placeholder":_vm.$t('PROFILE_SETTINGS.FORM.CURRENT_PASSWORD.PLACEHOLDER'),"error":_vm.$v.currentPassword.$error
                ? _vm.$t('PROFILE_SETTINGS.FORM.CURRENT_PASSWORD.ERROR')
                : ''},on:{"blur":_vm.$v.currentPassword.$touch},model:{value:(_vm.currentPassword),callback:function ($$v) {_vm.currentPassword=$$v},expression:"currentPassword"}})],1),_vm._v(" "),_c('div',{staticClass:"col-6"},[_c('woot-input',{class:{ error: _vm.$v.password.$error },attrs:{"type":"password","label":_vm.$t('PROFILE_SETTINGS.FORM.PASSWORD.LABEL'),"placeholder":_vm.$t('PROFILE_SETTINGS.FORM.PASSWORD.PLACEHOLDER'),"error":_vm.$v.password.$error
                ? _vm.$t('PROFILE_SETTINGS.FORM.PASSWORD.ERROR')
                : ''},on:{"blur":_vm.$v.password.$touch},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_vm._v(" "),_c('div',{staticClass:"col-6"},[_c('woot-input',{class:{ error: _vm.$v.passwordConfirmation.$error },attrs:{"type":"password","label":_vm.$t('PROFILE_SETTINGS.FORM.PASSWORD_CONFIRMATION.LABEL'),"placeholder":_vm.$t('PROFILE_SETTINGS.FORM.PASSWORD_CONFIRMATION.PLACEHOLDER'),"error":_vm.$v.passwordConfirmation.$error
                ? _vm.$t('PROFILE_SETTINGS.FORM.PASSWORD_CONFIRMATION.ERROR')
                : ''},on:{"blur":_vm.$v.passwordConfirmation.$touch},model:{value:(_vm.passwordConfirmation),callback:function ($$v) {_vm.passwordConfirmation=$$v},expression:"passwordConfirmation"}})],1),_vm._v(" "),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"text-end"},[_c('woot-button',{attrs:{"is-loading":_vm.isPasswordChanging,"type":"submit","disabled":!_vm.currentPassword ||
                !_vm.passwordConfirmation ||
                !_vm.$v.passwordConfirmation.isEqPassword}},[_vm._v("\n              "+_vm._s(_vm.$t('PROFILE_SETTINGS.FORM.PASSWORD_SECTION.BTN_TEXT'))+"\n            ")])],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }