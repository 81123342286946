import { frontendURL } from '../../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/reports'),
      component: () => import('../Wrapper'),
      props: {
        headerTitle: 'REPORT.HEADER',
        icon: 'chat',
        keepAlive: false,
      },
      children: [
        {
          path: '',
          redirect: 'live',
        },
        {
          path: 'conversation',
          name: 'conversation_reports',
          roles: ['administrator'],
          component: () => import('./ConversationList'),
          meta: {
            permission: 'show_reports',
          },
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/reports'),
      component: () => import('../Wrapper'),
      props: {
        headerTitle: 'OVERVIEW_REPORTS.HEADER',
        icon: 'arrow-trending-lines',
        keepAlive: false,
      },
      children: [
        {
          path: 'live',
          name: 'account_overview_reports',
          roles: ['administrator'],
          component: () => import('./newReport/LiveReports'),
          meta: {
            permission: 'show_reports',
          },
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/reports'),
      component: () => import('../Wrapper'),
      props: {
        headerTitle: 'MESSAGES.HEADER',
        icon: 'message',
      },
      children: [
        {
          path: 'messages',
          name: 'messages_reports',
          roles: ['administrator'],

          component: () => import('./message/Message'),
          meta: {
            permission: 'show_reports',
          },
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/reports'),
      component: () => import('../Wrapper'),
      props: {
        headerTitle: 'BROADCAST.HEADER',
        icon: 'cast',
      },
      children: [
        {
          path: 'broadcast',
          name: 'broadcast_reports',
          roles: ['administrator'],

          component: () => import('./broadcast/Index'),
          meta: {
            permission: 'show_reports',
          },
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/reports'),
      component: () => import('../Wrapper'),
      props: {
        headerTitle: 'CSAT_REPORTS.HEADER',
        icon: 'emoji',
        keepAlive: false,
      },
      children: [
        {
          path: 'csat',
          name: 'csat_reports',
          roles: ['administrator'],

          component: () => import('./CsatResponses'),
          meta: {
            permission: 'show_reports',
          },
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/reports'),
      component: () => import('../Wrapper'),
      props: {
        headerTitle: 'AGENT_REPORTS.HEADER',
        icon: 'people',
        keepAlive: false,
      },
      children: [
        {
          path: 'agent',
          name: 'agent_reports',
          roles: ['administrator'],

          component: () => import('./NewAgentReports'),
          meta: {
            permission: 'show_reports',
          },
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/reports'),
      component: () => import('../Wrapper'),
      props: {
        headerTitle: 'LABEL_REPORTS.HEADER',
        icon: 'tag',
        keepAlive: false,
      },
      children: [
        {
          path: 'label',
          name: 'label_reports',
          roles: ['administrator'],

          component: () => import('./LabelReports'),
          meta: {
            permission: 'show_reports',
          },
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/reports'),
      component: () => import('../Wrapper'),
      props: {
        headerTitle: 'INBOX_REPORTS.HEADER',
        icon: 'mail-inbox-all',
        keepAlive: false,
      },
      children: [
        {
          path: 'inboxes',
          name: 'inbox_reports',
          roles: ['administrator'],

          component: () => import('./InboxReports'),
          meta: {
            permission: 'show_reports',
          },
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/reports'),
      component: () => import('../Wrapper'),
      props: {
        headerTitle: 'CONTACTS_REPORTS.HEADER',
        icon: 'people-team',
      },
      children: [
        {
          path: 'contacts',
          name: 'contacts_reports',
          roles: ['administrator'],

          component: () => import('./ContactsReports'),
          meta: {
            permission: 'show_reports',
          },
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/reports'),
      component: () => import('../Wrapper'),
      props: {
        headerTitle: 'LOGS_REPORTS.HEADER',
        icon: 'docs',
      },
      children: [
        {
          path: 'logs',
          name: 'logs_reports',
          roles: ['administrator'],

          component: () => import('./LogsReports'),
          meta: {
            permission: 'show_reports',
          },
        },
      ],
    },
  ],
};
