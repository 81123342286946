<template>
  <transition-group name="toast-fade" tag="div" class="ui-snackbar-container">
    <woot-snackbar
      v-for="snackMessage in snackMessages"
      :key="snackMessage.key"
      :message="snackMessage.message"
      :action="snackMessage.action"
    />
  </transition-group>
</template>

<script>
import WootSnackbar from './Snackbar';

export default {
  components: {
    WootSnackbar,
  },
  props: {
    duration: {
      type: Number,
      default: 2500,
    },
  },

  data() {
    return {
      snackMessages: [],
    };
  },

  mounted() {
    bus.$on('newToastMessage', this.onNewToastMessage);
  },
  beforeDestroy() {
    bus.$off('newToastMessage', this.onNewToastMessage);
  },
  methods: {
    onNewToastMessage(message, type = 'success', action) {
      const options = {
        message,
        type,
        top: false,
        bottom: true,
        left: true,
        right: false,
        showClose: true,
        closeDelay: 7500,
      };
      // check if have action that contain link adding 2 keys and values in options object
      if (action) {
        options['actionText'] = action.message;
        options['onActionClick'] = () => this.$router.push({ path: action.to });
      }
      this.$notify({
        ...options,
      });
    },
  },
};
</script>

<style>
.notification {
  z-index: 20000 !important;
}
</style>
