<template>
  <modal
    :show.sync="show"
    :on-close="closeModal"
    :header-title="title"
    :header-content="message"
    class="no-border"
  >
    <form>
      <woot-input
        v-model="value"
        type="text"
        :class="{ error: $v.value.$error }"
        :placeholder="confirmPlaceHolderText"
        @blur="$v.value.$touch"
      />
    </form>
    <template #footer>
      <div class="button-wrapper">
        <woot-button class="clear" @click.prevent="closeModal">
          {{ rejectText }}
        </woot-button>
        <woot-button
          color-scheme="alert"
          :is-disabled="$v.value.$invalid"
          @click="onConfirm"
        >
          {{ confirmText }}
        </woot-button>
      </div>
    </template>
  </modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import Modal from '../../Modal';

export default {
  components: {
    Modal,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    confirmText: {
      type: String,
      default: '',
    },
    rejectText: {
      type: String,
      default: '',
    },
    confirmValue: {
      type: String,
      default: '',
    },
    confirmPlaceHolderText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      value: '',
    };
  },
  validations: {
    value: {
      required,
      isEqual(value) {
        return value === this.confirmValue;
      },
    },
  },
  methods: {
    closeModal() {
      this.value = '';
      this.$emit('on-close');
    },
    onConfirm() {
      this.value = ''
      this.$emit('on-confirm');
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .modal-content {
    padding: 0 24px !important;
  }
}
</style>
