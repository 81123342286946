/* global axios */

import ApiClient from './ApiClient';

class RoleAPI extends ApiClient {
  constructor() {
    super('roles', { accountScoped: true });
  }

  getRoles() {
    return axios.get(this.url);
  }
}

export default new RoleAPI();
