/* global axios */
import ApiClient from '../ApiClient';

class WorkflowTemplates extends ApiClient {
  constructor() {
    super('workflow_templates', { accountScoped: true, apiVersion: 'v2' });
  }

  create(id, template) {
    return axios.post(`${this.url}/${id}/use_template`, template);
  }
}

export default new WorkflowTemplates();
