import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../mutation-types';
import BevaAiAPI from '../../api/bavaAI';

export const state = {
  records: [],
  meta: {},
  uiFlags: {
    isFetching: true,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
  },
  promptsSetting: {},
  completedKnowledgeSource: [],
};

export const getters = {
  getKnowledgeSources($state) {
    return $state.records;
  },
  getKnowledgeMeta($state) {
    return $state.meta;
  },
  getSingleKnowledge: $state => id => {
    return $state.records[id] || [];
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getCompletedKnowledgeSource($state) {
    return $state.completedKnowledgeSource;
  },
};

export const actions = {
  get: async ({ commit }, params) => {
    commit(types.default.SET_BEVA_AI_UI_FLAG, { isFetching: true });
    try {
      const response = await BevaAiAPI.getKnowledgeSources(params);
      commit(types.default.SET_KNOWLEDGE_SOURCE, response.data);
    } catch (error) {
      throw error;
    } finally {
      commit(types.default.SET_BEVA_AI_UI_FLAG, { isFetching: false });
    }
  },
  create: async ({ commit }, { knowledgeSource }) => {
    commit(types.default.SET_BEVA_AI_UI_FLAG, { isCreating: true });
    try {
      const response = await BevaAiAPI.create(knowledgeSource);
      commit(types.default.ADD_KNOWLEDGE, response.data);
    } catch (error) {
      throw error;
    } finally {
      commit(types.default.SET_BEVA_AI_UI_FLAG, { isCreating: false });
    }
  },
  update: async ({ commit }, { id, knowledgeSource }) => {
    commit(types.default.SET_BEVA_AI_UI_FLAG, { isUpdating: true });
    try {
      const response = await BevaAiAPI.update(id, knowledgeSource);
      commit(types.default.EDIT_KNOWLEDGE, response.data);
    } catch (error) {
      throw error;
    } finally {
      commit(types.default.SET_BEVA_AI_UI_FLAG, { isUpdating: false });
    }
  },
  delete: async ({ commit }, knowledgeSourceId) => {
    commit(types.default.SET_BEVA_AI_UI_FLAG, { isDeleting: true });
    try {
      await BevaAiAPI.delete(knowledgeSourceId);
      commit(types.default.DELETE_KNOWLEDGE, knowledgeSourceId);
    } catch (error) {
      throw error;
    } finally {
      commit(types.default.SET_BEVA_AI_UI_FLAG, { isDeleting: false });
    }
  },

  getCompletedKnowledgeSource: async ({ commit }, params) => {
    commit(types.default.SET_BEVA_AI_UI_FLAG, { isFetching: true });
    try {
      const response = await BevaAiAPI.getCompletedKnowledgeSources(params);
      commit(types.default.SET_COMPLETED_KNOWLEDGE_SOURCE, response.data);
    } catch (error) {
      throw error;
    } finally {
      commit(types.default.SET_BEVA_AI_UI_FLAG, { isFetching: false });
    }
  },
};

export const mutations = {
  [types.default.SET_BEVA_AI_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },

  [types.default.SET_KNOWLEDGE_SOURCE]($state, data) {
    $state.records = data.payload;
    $state.meta = data.meta;
  },

  [types.default.ADD_KNOWLEDGE]: MutationHelpers.create,
  [types.default.EDIT_KNOWLEDGE]: MutationHelpers.update,
  [types.default.DELETE_KNOWLEDGE]: MutationHelpers.destroy,
  [types.default.SET_COMPLETED_KNOWLEDGE_SOURCE]($state, data) {
    $state.completedKnowledgeSource = data.payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
