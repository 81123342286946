import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      currentAccountId: 'getCurrentAccountId',
    }),
  },
  methods: {
    hasPermission(permission) {
      const currentAccount = this.currentUser.accounts.find(
        account => account.id === this.currentAccountId
      );
      if (currentAccount?.role === 'administrator') return true;
      return currentAccount
        ? currentAccount.permissions.includes(permission)
        : false;
    },
  },
};
