import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../mutation-types';
import SallaAPI from '../../api/salla';

export const state = {
  records: {
    events: [],
    currentEvent: {},
    inboxes: [],
    token: "",
    status: "",
  },
  uiFlags: {
    isFetching: false,
    isUpdating: false,
  },
};

export const getters = {
  getEvents($state) {
    return $state.records.events;
  },
  getInboxesIDs($state) {
    return $state.records.inboxes;
  },
  getEvent: $state => {
    return $state.records.currentEvent;
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getStatus($state) {
    return $state.records.status;
  },
  getToken($state) {
    return $state.records.token;
  },
};

export const actions = {
  getSallaInboxes: async ({ commit }) => {
    commit(types.default.SET_EVENT_UI_FLAG, { isFetching: true });
    try {
      const response = await SallaAPI.getSallaInboxes();
      commit(types.default.SET_EVENT_UI_FLAG, { isFetching: false });
      commit(types.default.SET_SALLA_INBOXES, response.data);
    } catch (error) {
      commit(types.default.SET_EVENT_UI_FLAG, { isFetching: false });
    }
  },
  getAllEvents: async ({ commit }, inbox_id) => {
    commit(types.default.SET_EVENT_UI_FLAG, { isFetching: true });
    try {
      const response = await SallaAPI.getAllEvents(inbox_id);
      commit(types.default.SET_EVENT_UI_FLAG, { isFetching: false });
      commit(types.default.SET_ALL_EVENTS, response.data);
    } catch (error) {
      commit(types.default.SET_EVENT_UI_FLAG, { isFetching: false });
    }
  },
  getEvent: async ({ commit }, params) => {
    commit(types.default.SET_EVENT_UI_FLAG, { isFetching: true });
    try {
      const response = await SallaAPI.getEvent(params);
      commit(types.default.SET_EVENT_UI_FLAG, { isFetching: false });
      commit(types.default.SET_EVENT, response.data);
    } catch (error) {
      commit(types.default.SET_EVENT_UI_FLAG, { isFetching: false });
    }
  },
  updateEvent: async ({ commit }, { id, ...eventParams }) => {
    commit(types.default.SET_EVENT_UI_FLAG, { isUpdating: true });
    try {
      const response = await SallaAPI.update({ id, eventParams });
      commit(types.default.EDIT_EVENT, { id, enabled: eventParams.enabled });
      commit(types.default.SET_EVENT_UI_FLAG, { isUpdating: false });
    } catch (error) {
      commit(types.default.SET_EVENT_UI_FLAG, { isUpdating: false });
      throw error;
    }
  },
  sallaConnection: async ({ commit }) => {
    commit(types.default.SET_EVENT_UI_FLAG, { isFetching: true });
    try {
      const response = await SallaAPI.getSallaConfig();
      commit(types.default.SET_EVENT_UI_FLAG, { isFetching: false });
      commit(types.default.SET_SALLA_CONFIGURATION, response.data);
    } catch (error) {
      commit(types.default.SET_EVENT_UI_FLAG, { isFetching: false });
    }
  },
  sallaCheck: async ({ commit }) => {
    commit(types.default.SET_EVENT_UI_FLAG, { isFetching: true });
    try {
      const response = await SallaAPI.checkSallaStatue();
      commit(types.default.SET_EVENT_UI_FLAG, { isFetching: false });
      commit(types.default.SET_SALLA_STATUS, response.data);
    } catch (error) {
      commit(types.default.SET_EVENT_UI_FLAG, { isFetching: false });
    }
  }
};

export const mutations = {
  [types.default.SET_EVENT_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },

  [types.default.SET_ALL_EVENTS]($state, data) {
    $state.records.events = data.events
  },
  [types.default.SET_SALLA_INBOXES]($state, data) {
    $state.records.inboxes = data.inboxes
  },
  [types.default.SET_SALLA_CONFIGURATION]($state, data) {
    $state.records.status = data.status;
    $state.records.token = data.connection_token;
  },
  [types.default.SET_SALLA_STATUS]($state, data) {
    $state.records.status = data.status;
  },
  [types.default.SET_EVENT]($state, data) {
    $state.records.currentEvent = data
  },
  [types.default.EDIT_EVENT]($state, data) {
    if ($state.records.events.length) {
      let event = $state.records.events.find(event => event.id == data.id);
      if (event) // for multi statues
        event.enabled = data.enabled;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
