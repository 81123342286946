/* global axios */
import ApiClient from './ApiClient';

class WorkFlowsAPI extends ApiClient {
  constructor() {
    super('workflows', { accountScoped: true, apiVersion: 'v2' });
  }
  get(params) {
    return axios.get(`${this.url}`, {
      params: {
        page: params.page,
      },
    });
  }
  shortcuts() {
    return axios.get(`${this.url}/shortcuts`);
  }

  startShortcuts(obj) {
    return axios.post(`${this.url}/${obj.workflowId}/start`, obj);
  }

  clone(workflowId) {
    return axios.post(`${this.url}/${workflowId}/clone`);
  }

  toggleActivation(workflowId, active) {
    return axios.patch(`${this.url}/${workflowId}/toggle_activation`);
  }

  attachment(workflowId, formData) {
    return axios.post(`${this.url}/${workflowId}/upload_attachment`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  getTriggerablesWorkFlows() {
    return axios.get(`${this.url}/triggerables_by_workflow`);
  }

  generateWebhook(workflowId, isNew) {
    return axios.post(`${this.url}/${workflowId}/generate_webhook_url`, {
      new: isNew,
    });
  }

  importWorkflow(uploadedWorkFlow) {
    return axios.post(`${this.url}/import` , uploadedWorkFlow)
  }
}

export default new WorkFlowsAPI();
