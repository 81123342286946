/* global axios */
import ApiClient from '../ApiClient';

class ReportBroadcast extends ApiClient {
  constructor() {
    super('reports', { accountScoped: true, apiVersion: 'v3' });
  }

  get(params) {
    return axios.get(`${this.url}/campaign_summary`, {
      params: {
        since: params.since,
        until: params.until,
        campaign_id: params.campaign_id,
        inbox_id: params.inbox_id,
        page: params.pageIndex,
        content: params.search_key,
      },
    });
  }
}

export default new ReportBroadcast();
