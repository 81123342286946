/* global axios */

import ApiClient from './ApiClient';

class ClosingNote extends ApiClient {
  constructor() {
    super('closing_notes', { accountScoped: true });
  }

  get({ searchKey }) {
    const url = searchKey ? `${this.url}?search=${searchKey}` : this.url;
    return axios.get(url);
  }
}

export default new ClosingNote();
