/* global axios */
import ApiClient from './ApiClient';

class AgentLogs extends ApiClient {
  constructor() {
    super('agent_session_logs', { accountScoped: true, apiVersion: 'v3' });
  }

  getAgentLogs(params) {
    return axios.get(`${this.url}`, {
      params: {
        since: params.since,
        until: params.until,
        page: params.page,
        agent_id: params.agent_id,
        team_id: params.team_id,
        search: params.search,
      },
    });
  }
}

export default new AgentLogs();
