import { frontendURL } from '../../helper/URLHelper';

export const routes = [
  {
    path: frontendURL('accounts/:accountId/search'),
    name: 'search',
    roles: ['administrator', 'agent'],
    component: () => import('./components/SearchView.vue'),
  },
];
