import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import WorkFlowsAPI from '../../api/workflows';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isDeleting: false,
    isUpdating: false,
    isGenerated: false,
  },
  shortcuts: [],
  shortcutsFlags: {
    isFetching: false,
    isStarting: false,
  },
  webhook: {
    url: null,
  },
  workflow: {},
  meta: {},
  triggerablesWorkFlows: [],
};

export const getters = {
  getWorkflows(_state) {
    return _state.records;
  },
  getTriggerablesWorkFlows(_state) {
    return _state.triggerablesWorkFlows;
  },
  getWorkflow: _state => id => {
    return _state.records.find(record => record.id === Number(id));
  },
  getSingleWorkflow: _state => {
    return _state.workflow;
  },
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  getShortcuts:
    _state =>
    (search = '') => {
      if (search != '') {
        return _state.shortcuts.filter(el => el.name.includes(search));
      }
      return _state.shortcuts;
    },
  getShortcutsUiFLags(_state) {
    return _state.shortcutsFlags;
  },
  getWebhook(_state) {
    return _state.webhook;
  },
  meta(_state) {
    return _state.meta;
  },
  getCurrentWorkFlowInbox(_state){
    return _state.workflow.inbox_id;
  }
};

export const actions = {
  get: async function getWorkflows({ commit }, params) {
    commit(types.SET_WORKFLOW_UI_FLAG, { isFetching: true });
    try {
      const response = await WorkFlowsAPI.get(params);
      commit(types.SET_WORKFLOWS, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_WORKFLOW_UI_FLAG, { isFetching: false });
    }
  },
  getSingleWorkflow: async function getWorkflowById({ commit }, WorkflowId) {
    commit(types.SET_WORKFLOW_UI_FLAG, { isFetchingItem: true });
    try {
      const response = await WorkFlowsAPI.show(WorkflowId);
      commit(types.ADD_SINGLE_WORKFLOW, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_WORKFLOW_UI_FLAG, { isFetchingItem: false });
    }
  },
  getShortcuts: async function getShortcuts({ commit }) {
    commit(types.SET_SHORTCUTS_UI_FLAG, { isFetching: true });
    try {
      const response = await WorkFlowsAPI.shortcuts();
      commit(types.SET_SHORTCUTS, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_SHORTCUTS_UI_FLAG, { isFetching: false });
    }
  },
  startShortcuts: async function startShortcuts({ commit }, obj) {
    commit(types.SET_SHORTCUTS_UI_FLAG, { isStarting: true });
    try {
      await WorkFlowsAPI.startShortcuts(obj);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_SHORTCUTS_UI_FLAG, { isStarting: false });
    }
  },
  create: async function createWorkflow({ commit }, automationObj) {
    commit(types.SET_WORKFLOW_UI_FLAG, { isCreating: true });
    try {
      const response = await WorkFlowsAPI.create(automationObj);
      commit(types.ADD_WORKFLOW, response.data);
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      commit(types.SET_WORKFLOW_UI_FLAG, { isCreating: false });
    }
  },
  import: async function importWorkflow({ commit }, uploadedWorkFlow) {
    commit(types.SET_WORKFLOW_UI_FLAG, { isCreating: true });
    try {
      const response = await WorkFlowsAPI.importWorkflow(uploadedWorkFlow);
      commit(types.ADD_WORKFLOW, response.data);
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      commit(types.SET_WORKFLOW_UI_FLAG, { isCreating: false });
    }
  },
  update: async ({ commit }, { id, ...updateObj }) => {
    commit(types.SET_WORKFLOW_UI_FLAG, { isUpdating: true });
    try {
      const response = await WorkFlowsAPI.update(id, updateObj);
      commit(types.EDIT_WORKFLOW, response.data);
      commit(types.ADD_SINGLE_WORKFLOW, response.data);
    } catch (error) {
      // window.bus.$emit('newToastMessage', error.response.data.message);
      throw error;
    } finally {
      commit(types.SET_WORKFLOW_UI_FLAG, { isUpdating: false });
    }
  },

  delete: async ({ commit }, id) => {
    commit(types.SET_WORKFLOW_UI_FLAG, { isDeleting: true });
    try {
      await WorkFlowsAPI.delete(id);
      commit(types.DELETE_WORKFLOW, id);
    } catch (error) {
      throw error;
    } finally {
      commit(types.SET_WORKFLOW_UI_FLAG, { isDeleting: false });
    }
  },
  clone: async (context, params) => {
    context.commit(types.SET_WORKFLOW_UI_FLAG, { isCloning: true });
    try {
      await WorkFlowsAPI.clone(params.id);
      context.dispatch('get', { page: params.page });
    } catch (error) {
      throw error;
    } finally {
      context.commit(types.SET_WORKFLOW_UI_FLAG, { isCloning: false });
    }
  },
  toggleActivation: async (context, { id, active }) => {
    context.commit(types.SET_WORKFLOW_UI_FLAG, { isCloning: true });
    try {
      const response = await WorkFlowsAPI.toggleActivation(id, active);
      context.commit(types.EDIT_WORKFLOW, response.data);
      if (context.state.workflow.id === id) {
        context.commit(types.ADD_SINGLE_WORKFLOW, response.data);
      }
    } catch (error) {
      throw error;
    } finally {
      context.commit(types.SET_WORKFLOW_UI_FLAG, { isCloning: false });
    }
  },
  uploadAttachment: async (_, { workflowId, formData }) => {
    try {
      const { data } = await WorkFlowsAPI.attachment(workflowId, formData);
      return data;
    } catch (error) {
      throw error;
    }
  },
  getTriggerables: async ({ commit }) => {
    commit(types.SET_WORKFLOW_UI_FLAG, { isFetching: true });
    try {
      const response = await WorkFlowsAPI.getTriggerablesWorkFlows();
      commit(types.SET_Triggerables_WORKFLOWS, response.data);
    } catch (error) {
      throw error;
    } finally {
      commit(types.SET_WORKFLOW_UI_FLAG, { isFetching: false });
    }
  },
  // webhook
  generateWebhook: async ({ commit }, { workflowId, isNew }) => {
    commit(types.SET_WORKFLOW_UI_FLAG, { isGenerated: true });
    try {
      const response = await WorkFlowsAPI.generateWebhook(workflowId, isNew);
      commit(types.SET_WORKFLOW_TRIGGER_WEBHOOK, response.data);
    } catch (error) {
      throw error;
    } finally {
      commit(types.SET_WORKFLOW_UI_FLAG, { isGenerated: false });
    }
  },
};

export const mutations = {
  [types.SET_WORKFLOW_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
  [types.SET_SHORTCUTS_UI_FLAG](_state, data) {
    _state.shortcutsFlags = {
      ...data,
    };
  },
  [types.SET_SHORTCUTS](_state, data) {
    _state.shortcuts = [...data];
  },
  [types.ADD_SINGLE_WORKFLOW](_state, data) {
    _state.workflow = data;
  },
  [types.SET_WORKFLOWS](_state, data) {
    _state.records = data.payload;
    _state.meta = data.meta;
  },
  [types.SET_Triggerables_WORKFLOWS](_state, data) {
    _state.triggerablesWorkFlows = data.payload;
  },
  [types.SET_WORKFLOW_TRIGGER_WEBHOOK](_state, data) {
    _state.webhook.url = data.webhook_url;
  },
  [types.ADD_WORKFLOW]: MutationHelpers.create,
  // [types.SET_WORKFLOWS]: MutationHelpers.set,
  [types.EDIT_WORKFLOW]: MutationHelpers.update,
  [types.DELETE_WORKFLOW]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
