/* eslint no-console: 0 */
import * as types from '../mutation-types';
import AgentLogs from '../../api/agentLogs';

const state = {
  fetchingStatus: false,
  agent: {
    data: [],
    meta: {},
  },
};

const getters = {
  getAgentLogsData(_state) {
    return _state.agent;
  },
  getAgentLogsUIFlags(_state) {
    return _state.fetchingStatus;
  },
};

export const actions = {
  async fetchAgentLogs({ commit }, reportObj) {
    commit(types.default.TOGGLE_AGENT_LOG, true);
    try {
      const { data } = await AgentLogs.getAgentLogs(reportObj);
      commit(types.default.SET_AGENT_LOG, data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.default.TOGGLE_AGENT_LOG, false);
    }
  },
};

const mutations = {
  [types.default.SET_AGENT_LOG](_state, data) {
    _state.agent.data = data.payload;
    _state.agent.meta = data.meta;
  },
  [types.default.TOGGLE_AGENT_LOG](_state, flag) {
    _state.fetchingStatus = flag;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
