import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../mutation-types';
import SubscriptionAPI from '../../api/subscription';
import { Date } from 'core-js';

export const state = {
  records: {
    info: {},
    addons: {},
    subscriptions: {
      payload: [],
      meta: {}
    }
  },
  uiFlags: {
    isFetchingInfo: false,
    isFetchingAddons: false,
    isFetchingSubscriptions: false,
  },
};

export const getters = {
  getInfo($state) {
    return $state.records.info;
  },
  getAddons($state) {
    return $state.records.addons;
  },
  getSubscriptions($state) {
    return $state.records.subscriptions;
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
};

export const actions = {
  getSubscriptionInfo: async ({ commit }) => {
    commit(types.default.SET_SUBSCRIPTION_UI_FLAG, { isFetchingInfo: true });
    try {
      const response = await SubscriptionAPI.getSubscriptionInfo();
      commit(types.default.SET_SUBSCRIPTION_INFO, response.data);
      commit(types.default.CALC_SUBSCRIPTION_REMAINING_DAYS);
      commit(types.default.CALC_ADDONS_REMAINING_QUOTA);
    } catch (error) {
      // handle errors
    }
    finally {
      commit(types.default.SET_SUBSCRIPTION_UI_FLAG, { isFetchingInfo: false });
    }
  },

  getSubscriptions: async ({ commit }, params) => {
    commit(types.default.SET_SUBSCRIPTION_UI_FLAG, { isFetchingSubscriptions: true });
    try {
      const response = await SubscriptionAPI.getSubscriptions(params);
      commit(types.default.SET_SUBSCRIPTIONS, response.data);
    } catch (error) {
      // handle errors
    }
    finally {
      commit(types.default.SET_SUBSCRIPTION_UI_FLAG, { isFetchingSubscriptions: false });
    }
  },

  getAddons: async ({ commit }, params) => {
    commit(types.default.SET_SUBSCRIPTION_UI_FLAG, { isFetchingAddons: true });
    try {
      const response = await SubscriptionAPI.getAddons(params);
      commit(types.default.SET_SUBSCRIPTION_ADDONS, response.data);
    } catch (error) {
      // handle errors
    }
    finally {
      commit(types.default.SET_SUBSCRIPTION_UI_FLAG, { isFetchingAddons: false });
    }
  },
};

export const mutations = {
  [types.default.SET_SUBSCRIPTION_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },

  [types.default.SET_SUBSCRIPTION_INFO]($state, data) {
    $state.records.info = data;

    // calc next billing
    let endDate = $state.records.info.current_subscription_info.end_date;
    // get end date with milliseconds and add milliseconds of one day
    $state.records.info.current_subscription_info["next_billing"] = new Date((+(new Date(endDate))) + (24 * 60 * 60 * 1000)).toISOString().split('T')[0]
  },

  [types.default.CALC_SUBSCRIPTION_REMAINING_DAYS]($state) {
    // calculate remaining days
    let endDate = new Date($state.records.info.current_subscription_info.end_date);
    let today = new Date();
    const diffTime = endDate - today;
    // this 1000 * 60 * 60 * 24 => the number of milliseconds per day
    const remainingDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    $state.records.info.current_subscription_info["remaining_days"] = remainingDays > 0 ? remainingDays : 0;
  },

  [types.default.CALC_ADDONS_REMAINING_QUOTA]($state) {
    // calculate remaining addon usage
    let addons_remaining = {};
    let addonsLimits = $state.records.info.addons_limits;
    let addonsUsages = $state.records.info.addons_usages;
    let addonsKeys = Object.keys(addonsLimits);
    let addonsLengthKey = addonsKeys.length;

    for (let i = 0; i < addonsLengthKey; i++) {
      addons_remaining[addonsKeys[i]] = addonsLimits[addonsKeys[i]] - addonsUsages[addonsKeys[i]];
      if (addons_remaining[addonsKeys[i]] < 0) {
        addons_remaining[addonsKeys[i]] = 0
      }
    }
    $state.records.info["addons_remaining"] = addons_remaining;
  },

  [types.default.SET_SUBSCRIPTIONS]($state, data) {
    $state.records.subscriptions = data;
  },

  [types.default.SET_SUBSCRIPTION_ADDONS]($state, data) {
    $state.records.addons = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
