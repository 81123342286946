/* eslint no-console: 0 */
import * as types from '../mutation-types';
import AgentSessionLogs from '../../api/agentSessionLogs';

const state = {
  fetchingStatus: false,
  agent: {
    data: [],
    meta: {},
  },
};

const getters = {
  getAgentSessionLogsData(_state) {
    return _state.agent;
  },
  getAgentSessionLogsUIFlags(_state) {
    return _state.fetchingStatus;
  },
};

export const actions = {
  async fetchAgentSessionLogs({ commit }, reportObj) {
    commit(types.default.TOGGLE_AGENT_SESSION_LOG, true);
    try {
      const { data } = await AgentSessionLogs.getAgentLogs(reportObj);
      commit(types.default.SET_AGENT_SESSION_LOG, data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.default.TOGGLE_AGENT_SESSION_LOG, false);
    }
  },
};

const mutations = {
  [types.default.SET_AGENT_SESSION_LOG](_state, data) {
    _state.agent.data = data.payload;
    _state.agent.meta = data.meta;
  },
  [types.default.TOGGLE_AGENT_SESSION_LOG](_state, flag) {
    _state.fetchingStatus = flag;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
