import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../mutation-types';
import BlockListApi from '../../api/blockList';

const state = {
  records: {},
  uiFlags: {
    isFetching: false,
    isUnblocking: false,
  },
};

export const getters = {
  getBlockList($state) {
    return $state.records.payload;
  },
  meta($state) {
    return $state.records.meta;
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
};

export const actions = {
  get: async ({ commit }, params) => {
    commit(types.default.SET_BLOCK_LIST_UI_FLAG, { isFetching: true });
    try {
      const response = await BlockListApi.getBlockList(params);
      commit(types.default.GET_BLOCK_LIST, response.data);
      commit(types.default.SET_BLOCK_LIST_UI_FLAG, {
        isFetching: false,
      });
    } catch (error) {
      commit(types.default.SET_BLOCK_LIST_UI_FLAG, {
        isFetching: false,
      });
    }
  },

  unblock: async ({ commit }, payload) => {
    commit(types.default.SET_BLOCK_LIST_UI_FLAG, {
      isBLocking: true,
    });
    try {
      await BlockListApi.unblockContact(payload);
      commit(types.default.UNBLOCK, payload.id);
      commit(types.default.SET_BLOCK_LIST_UI_FLAG, {
        isBLocking: false,
      });
    } catch (error) {
      commit(types.default.SET_BLOCK_LIST_UI_FLAG, {
        isBLocking: false,
      });
    }
  },

  unblockAll: async ({ commit }, payload) => {
    commit(types.default.SET_BLOCK_LIST_UI_FLAG, {
      isBLocking: true,
    });
    try {
      await BlockListApi.unblockAll(payload);
      commit(types.default.UNBLOCKALL, payload.ids);
      commit(types.default.SET_BLOCK_LIST_UI_FLAG, {
        isBLocking: false,
      });
    } catch (error) {
      commit(types.default.SET_BLOCK_LIST_UI_FLAG, {
        isBLocking: false,
      });
    }
  },
};

export const mutations = {
  [types.default.SET_BLOCK_LIST_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.default.GET_BLOCK_LIST]: MutationHelpers.set,
  [types.default.UNBLOCK]: MutationHelpers.destroy,
  [types.default.UNBLOCKALL]: MutationHelpers.destroyAll,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
