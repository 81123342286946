<template>
  <router-link v-slot="{ href, isActive, navigate }" :to="to" custom>
    <a
      v-tooltip.right="$t(`SIDEBAR.${name}`)"
      :href="href"
      class="button clear button--only-icon menu-item"
      :class="{ 'is-active': isActive || isChildMenuActive }"
      :rel="openInNewPage ? 'noopener noreferrer nofollow' : undefined"
      :target="openInNewPage ? '_blank' : undefined"
      @click="navigate"
    >
      <span class="badge danger" v-if="showAlert"></span>
      <img :src="'/dashboard/images/sidebar/' + icon + '.svg'" alt="" />
      <!-- <fluent-icon :icon="icon" /> -->
      <span class="show-for-sr">{{ name }}</span>
      <span v-if="count" class="badge warning">{{ count }}</span>
    </a>
  </router-link>
</template>
<script>
export default {
  props: {
    to: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    count: {
      type: String,
      default: '',
    },
    showAlert: {
      type: Boolean,
      default: false,
    },
    isChildMenuActive: {
      type: Boolean,
      default: false,
    },
    openInNewPage: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.button {
  margin: var(--space-small) 0;
}

.menu-item {
  display: inline-flex;
  position: relative;
  border-radius: 8px;
  border: 1px solid transparent;
  color: #fff;
  padding: 10px;

  &:hover {
    // background: var(--w-25);
    // color: var(--s-600);
    background: rgba(#000000, 0.4);
    color: var(--w-200);
  }

  // &:focus {
  //   border-color: var(--w-500);
  // }

  img {
    filter: brightness(0) invert(1);
    transition: filter 0.4s;
  }

  &.is-active {
    // background: var(--w-50);
    // color: var(--w-500);
    // background: rgba(#000000, 0.4);
    // color: #98daf6;
    border-radius: 4px;
    border-inline-start: 2px solid var(--w-300);
    background: linear-gradient(
      117deg,
      rgba(0, 0, 0, 0.32) 16.67%,
      rgba(0, 0, 0, 0.32) 134.97%
    );

    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.08);
    backdrop-filter: blur(50px);

    img {
      filter: brightness(1) invert(0);
    }
  }
}

.icon {
  font-size: var(--font-size-default);
}

.badge {
  position: absolute;
  right: var(--space-minus-smaller);
  top: var(--space-minus-smaller);
  &.danger {
    background: red;
    width: 7px;
    height: 7px;
    min-width: 7px;
    border-radius: 50%;
  }
}
</style>
