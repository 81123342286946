import { frontendURL } from '../../../helper/URLHelper';

export const routes = [
  {
    path: frontendURL('accounts/:accountId/notifications'),
    component: () => import('../settings/Wrapper'),
    props: {
      headerTitle: 'NOTIFICATIONS_PAGE.HEADER',
      icon: 'alert',
      showNewButton: false,
      showSidemenuIcon: false,
    },
    children: [
      {
        path: '',
        name: 'notifications_index',
        component: () => import('./components/NotificationsView.vue'),
        roles: ['administrator', 'agent'],
      },
    ],
  },
];
