<template>
  <div class="d-flex flex-column align-items-center gap-3">
    <label v-if="label">
      <span>{{ label }}</span>
    </label>
    <woot-thumbnail
      size="80px"
      :src="src"
      :username="usernameAvatar"
      class="mb-4 border rounded-circle"
    />
    <div class="actions">
      <label>
        <input
          id="file"
          ref="file"
          type="file"
          accept="image/png, image/jpeg, image/gif"
          class="d-none"
          @change="handleImageUpload"
        />
        <div class="action-button upload-avatar">
          <p class="mb-0">{{ $t('GENERAL_SETTINGS.FORM.AVATAR.UPLOAD') }}</p>
          <span key="update" v-if="src">
            <i class="fa-solid fa-rotate"></i>
          </span>
          <span key="upload" v-else>
            <i class="fa-solid fa-arrow-up-from-bracket"></i>
          </span>
        </div>
      </label>
      <button
        v-if="src && deleteAvatar"
        type="button"
        class="action-button delete-avatar"
        @click="onAvatarDelete"
      >
        {{ $t('GENERAL_SETTINGS.FORM.AVATAR.DELETE') }}
        <i class="fa-regular fa-trash-can"></i>
      </button>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    src: {
      type: String,
      default: '',
    },
    usernameAvatar: {
      type: String,
      default: '',
    },
    deleteAvatar: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleImageUpload(event) {
      const [file] = event.target.files;

      this.$emit('change', {
        file,
        url: file ? URL.createObjectURL(file) : null,
      });
    },
    onAvatarDelete() {
      this.$refs.file.value = null;
      this.$emit('onAvatarDelete');
    },
  },
};
</script>

<style lang="scss" scoped>
.actions {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 8px;
  font-size: 14px;
  font-weight: 400;
  .action-button {
    cursor: pointer;
    padding: 10px 16px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border: 1px solid;
    line-height: normal;
  }
  .upload-avatar {
    border-color: var(--w-500);
    background: var(--w-50);
    color: var(--w-500);
  }
  .delete-avatar {
    border-color: var(--r-500);
    background: var(--r-50);
    color: var(--r-500);
  }
}
</style>
