import ApiClient from './ApiClient';

class ContactsReports extends ApiClient {
  constructor() {
    super('reports', { accountScoped: true, apiVersion: 'v3' });
    this.contactId = null;
  }

  get contactLogsUrl() {
    return `${this.baseUrl()}/contact_logs`;
  }

  getLogsSummary(params) {
    return axios.get(this.url + '/contact_logs_summary', { params });
  }

  getContactsLogsChart(params) {
    return axios.get(this.url, {
      params: { ...params, metric: 'contact_logs' },
    });
  }

  getAddedContactsLogsChart(params) {
    return axios.get(this.url, {
      params: { ...params, metric: 'added_contact_logs_by_source' },
    });
  }

  getContactsLogsTableData(params) {
    return axios.get(this.contactLogsUrl, {
      params: { ...params },
    });
  }

  getContactsLogsCount(params) {
    return axios.get(this.url + '/added_contact_logs_summary', {
      params: { ...params },
    });
  }
}

export default new ContactsReports();
