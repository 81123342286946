<template>
  <div class="wizard-body">
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.WHATSAPP.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.WHATSAPP.DESC')"
    />
    <div class="medium-8 columns">
      <label class="relative" style="padding: 0 0.75rem;">
        {{ $t('INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.LABEL') }}
        <div class="multiselect-wrap--small">
          <multiselect
            v-model="provider"
            :options="providers"
            track-by="value"
            label="name"
            :multiple="false"
            :searchable="false"
            :hide-selected="false"
            :allow-empty="false"
            selected-label
            :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
            :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
          />
        </div>
      </label>
    </div>

    <!-- <twilio v-if="provider.value === 'twilio'" type="whatsapp" /> -->
    <three-sixty-dialog-whatsapp v-if="provider.value === '360dialog'" />
    <cloud-whatsapp v-else />
  </div>
</template>

<script>
import PageHeader from '../../SettingsSubPageHeader';
import Twilio from './Twilio';
import ThreeSixtyDialogWhatsapp from './360DialogWhatsapp';
import CloudWhatsapp from './CloudWhatsapp';

export default {
  components: {
    PageHeader,
    Twilio,
    ThreeSixtyDialogWhatsapp,
    CloudWhatsapp,
  },
  data() {
    return {
      provider: {
        value: 'whatsapp_cloud',
        name: this.$t('INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.WHATSAPP_CLOUD'),
      },

      providers: [
        {
          value: 'whatsapp_cloud',
          name: this.$t('INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.WHATSAPP_CLOUD'),
        },
        // {
        //   value: 'twilio',
        //   name: this.$t('INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.TWILIO'),
        // },
        {
          value: '360dialog',
          name: this.$t('INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.360_DIALOG'),
        },
      ],
    };
  },
};
</script>
