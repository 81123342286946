import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../mutation-types';
import ClosingNote from '../../api/closingNote';

const state = {
  records: [],
  uiFlags: {
    fetchingList: false,
    fetchingItem: false,
    creatingItem: false,
    updatingItem: false,
    deletingItem: false,
  },
};

const getters = {
  getClosingNotes(_state) {
    return _state.records;
  },
  getUIFlags(_state) {
    return _state.uiFlags;
  },
};

const actions = {
  getClosingNote: async function getClosingNote(
    { commit },
    { searchKey } = {}
  ) {
    commit(types.default.SET_FILE_UI_FLAG, { fetchingList: true });
    try {
      const response = await ClosingNote.get({ searchKey });
      commit(types.default.SET_FILE, response.data);
      commit(types.default.SET_FILE_UI_FLAG, { fetchingList: false });
    } catch (error) {
      commit(types.default.SET_FILE_UI_FLAG, { fetchingList: false });
    }
  },

  createClosingNote: async function createClosingNote({ commit }, data) {
    commit(types.default.SET_FILE_UI_FLAG, { creatingItem: true });
    try {
      const response = await ClosingNote.create(data);
      commit(types.default.ADD_FILE, response.data);
    } catch (error) {
      const errorMessage = error?.response?.data?.message;
      throw new Error(errorMessage);
    } finally {
      commit(types.default.SET_FILE_UI_FLAG, { creatingItem: false });
    }
  },

  updateClosingNote: async function updateClosingNote(
    { commit },
    { id, ...updateObj }
  ) {
    commit(types.default.SET_FILE_UI_FLAG, { updatingItem: true });
    try {
      const response = await ClosingNote.update(id, updateObj);
      commit(types.default.EDIT_FILE, response.data);
    } catch (error) {
      const errorMessage = error?.response?.data?.message;
      throw new Error(errorMessage);
    } finally {
      commit(types.default.SET_FILE_UI_FLAG, { updatingItem: false });
    }
  },

  deleteClosingNote: async function deleteClosingNote({ commit }, id) {
    commit(types.default.SET_FILE_UI_FLAG, { deletingItem: true });
    try {
      await ClosingNote.delete(id);
      commit(types.default.DELETE_FILE, id);
      commit(types.default.SET_FILE_UI_FLAG, { deletingItem: true });
    } catch (error) {
      commit(types.default.SET_FILE_UI_FLAG, { deletingItem: true });
    }
  },
};

const mutations = {
  [types.default.SET_FILE_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.default.SET_FILE]: MutationHelpers.set,
  [types.default.ADD_FILE]: MutationHelpers.create,
  [types.default.EDIT_FILE]: MutationHelpers.update,
  [types.default.DELETE_FILE]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
