<template>
  <form @submit.prevent="changePassword()">
    <div class="setting-item">
      <div class="text">
        <h4 class="setting-item__text__title">
          {{ $t('PROFILE_SETTINGS.FORM.PASSWORD_SECTION.TITLE') }}
        </h4>
        <p class="setting-item__text__help-text">
          {{ $t('PROFILE_SETTINGS.FORM.PASSWORD_SECTION.NOTE') }}
        </p>
      </div>
      <div class="my-3">
        <div class="row">
          <div class="col-12">
            <woot-input
              v-model="currentPassword"
              type="password"
              :class="{ error: $v.currentPassword.$error }"
              :label="$t('PROFILE_SETTINGS.FORM.CURRENT_PASSWORD.LABEL')"
              :placeholder="
                $t('PROFILE_SETTINGS.FORM.CURRENT_PASSWORD.PLACEHOLDER')
              "
              :error="
                $v.currentPassword.$error
                  ? $t('PROFILE_SETTINGS.FORM.CURRENT_PASSWORD.ERROR')
                  : ''
              "
              @blur="$v.currentPassword.$touch"
            />
          </div>
          <div class="col-6">
            <woot-input
              v-model="password"
              type="password"
              :class="{ error: $v.password.$error }"
              :label="$t('PROFILE_SETTINGS.FORM.PASSWORD.LABEL')"
              :placeholder="$t('PROFILE_SETTINGS.FORM.PASSWORD.PLACEHOLDER')"
              :error="
                $v.password.$error
                  ? $t('PROFILE_SETTINGS.FORM.PASSWORD.ERROR')
                  : ''
              "
              @blur="$v.password.$touch"
            />
          </div>
          <div class="col-6">
            <woot-input
              v-model="passwordConfirmation"
              type="password"
              :class="{ error: $v.passwordConfirmation.$error }"
              :label="$t('PROFILE_SETTINGS.FORM.PASSWORD_CONFIRMATION.LABEL')"
              :placeholder="
                $t('PROFILE_SETTINGS.FORM.PASSWORD_CONFIRMATION.PLACEHOLDER')
              "
              :error="
                $v.passwordConfirmation.$error
                  ? $t('PROFILE_SETTINGS.FORM.PASSWORD_CONFIRMATION.ERROR')
                  : ''
              "
              @blur="$v.passwordConfirmation.$touch"
            />
          </div>
          <div class="col-12">
            <div class="text-end">
              <woot-button
                :is-loading="isPasswordChanging"
                type="submit"
                :disabled="
                  !currentPassword ||
                  !passwordConfirmation ||
                  !$v.passwordConfirmation.isEqPassword
                "
              >
                {{ $t('PROFILE_SETTINGS.FORM.PASSWORD_SECTION.BTN_TEXT') }}
              </woot-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  mixins: [alertMixin],
  data() {
    return {
      currentPassword: '',
      password: '',
      passwordConfirmation: '',
      isPasswordChanging: false,
    };
  },
  validations: {
    currentPassword: {
      required,
    },
    password: {
      minLength: minLength(6),
    },
    passwordConfirmation: {
      minLength: minLength(6),
      isEqPassword(value) {
        if (value !== this.password) {
          return false;
        }
        return true;
      },
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      currentUserId: 'getCurrentUserID',
    }),
  },
  methods: {
    async changePassword() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.showAlert(this.$t('PROFILE_SETTINGS.FORM.ERROR'), 'error');
        return;
      }

      try {
        await this.$store.dispatch('updateProfile', {
          password: this.password,
          password_confirmation: this.passwordConfirmation,
          current_password: this.currentPassword,
        });
        this.showAlert(this.$t('PROFILE_SETTINGS.PASSWORD_UPDATE_SUCCESS'));
      } catch (error) {
        this.showAlert(this.$t('RESET_PASSWORD.API.ERROR_MESSAGE'), 'error');
        if (error?.response?.data?.message) {
          this.showAlert(error.response.data.message, 'error');
        }
      } finally {
        this.isPasswordChanging = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.setting-item {
  border: 1px solid var(--b-100);
  padding: 24px 16px;
  border-radius: 16px;
  margin-bottom: 48px;
  &__text {
    &__title {
      color: var(--color-woot);
      opacity: 0.5;
      font-weight: 600;
      font-size: 18px;
    }
    &__help-text {
      color: var(--b-900);
      font-size: 14px;
      font-weight: 400;
    }
  }
}
</style>
