/* global axios */
import ApiClient from '../../ApiClient';
const getTimeOffset = () => -new Date().getTimezoneOffset() / 60;

class OutGoingMessageList extends ApiClient {
  constructor() {
    super('outgoing_logs', { accountScoped: true, apiVersion: 'v3' });
  }

  get(prams) {
    return axios.get(`${this.url}`, {
      params: {
        since: prams.since,
        until: prams.until,
        content: prams.search,
        inbox_id: prams.inbox_id,
        page: prams.pageIndex,
        team_id: prams.team_id,
        agent_id: prams.agent_id,
        timezone_offset: getTimeOffset(),
        sort: prams.sort?.order_column,
        direction: prams.sort?.order_direction,
      },
    });
  }

  download(prams) {
    return axios.get(`${this.url}/download`, {
      params: {
        since: prams.since,
        until: prams.until,
        content: prams.search,
        inbox_id: prams.inbox_id,
        page: prams.pageIndex,
        team_id: prams.team_id,
        agent_id: prams.agent_id,
        timezone_offset: getTimeOffset(),
      },
    });
  }
}

export default new OutGoingMessageList();
