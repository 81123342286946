import SearchAPI from '../../api/search';
import types from '../mutation-types';
export const initialState = {
  records: [],
  contactRecords: [],
  conversationRecords: [],
  messageRecords: [],
  uiFlags: {
    isFetching: false,
    isSearchCompleted: false,
    contact: { isFetching: false, isHaveMore: true },
    conversation: { isFetching: false, isHaveMore: true },
    message: { isFetching: false, isHaveMore: true },
  },
};

export const getters = {
  getConversations(state) {
    return state.records;
  },
  getContactRecords(state) {
    return state.contactRecords;
  },
  getConversationRecords(state) {
    return state.conversationRecords;
  },
  getMessageRecords(state) {
    return state.messageRecords;
  },
  getUIFlags(state) {
    return state.uiFlags;
  },
};

export const actions = {
  async get({ commit }, { q }) {
    commit(types.SEARCH_CONVERSATIONS_SET, []);
    if (!q) {
      return;
    }
    commit(types.SEARCH_CONVERSATIONS_SET_UI_FLAG, { isFetching: true });
    try {
      const {
        data: { payload },
      } = await SearchAPI.get({ q });
      commit(types.SEARCH_CONVERSATIONS_SET, payload);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SEARCH_CONVERSATIONS_SET_UI_FLAG, {
        isFetching: false,
      });
    }
  },
  async fullSearch({ commit, dispatch }, searchOptions) {
    if (!searchOptions.q) {
      return;
    }
    commit(types.FULL_SEARCH_SET_UI_FLAG, {
      isFetching: true,
      isSearchCompleted: false,
    });
    commit(types.SEARCH_CONVERSATIONS_RESET);
    try {
      await Promise.all([
        dispatch('contactsSearch', searchOptions),
        dispatch('conversationsSearch', searchOptions),
        dispatch('messagesSearch', searchOptions),
      ]);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.FULL_SEARCH_SET_UI_FLAG, {
        isFetching: false,
        isSearchCompleted: true,
      });
    }
  },
  async contactsSearch({ commit }, searchOptions) {
    commit(types.CONTACT_SEARCH_SET_UI_FLAG, { isFetching: true });
    try {
      const { data } = await SearchAPI.contacts(searchOptions);
      commit(types.CONTACT_SEARCH_SET, data.payload.contacts);
      commit(types.SEARCH_HAVE_MORE_SET,
        { type: 'contact', recordsLength: data.payload.contacts.length });
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.CONTACT_SEARCH_SET_UI_FLAG, { isFetching: false });
    }
  },
  async conversationsSearch({ commit }, searchOptions) {
    commit(types.CONVERSATION_SEARCH_SET_UI_FLAG, { isFetching: true });
    try {
      const { data } = await SearchAPI.conversations(searchOptions);
      commit(types.CONVERSATION_SEARCH_SET, data.payload.conversations);
      commit(types.SEARCH_HAVE_MORE_SET,
        { type: 'conversation', recordsLength: data.payload.conversations.length });
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.CONVERSATION_SEARCH_SET_UI_FLAG, { isFetching: false });
    }
  },
  async messagesSearch({ commit }, searchOptions) {
    commit(types.MESSAGE_SEARCH_SET_UI_FLAG, { isFetching: true });
    try {
      const { data } = await SearchAPI.messages(searchOptions);
      commit(types.MESSAGE_SEARCH_SET, data.payload.messages);
      commit(types.SEARCH_HAVE_MORE_SET,
        { type: 'message', recordsLength: data.payload.messages.length });
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.MESSAGE_SEARCH_SET_UI_FLAG, { isFetching: false });
    }
  },
  async clearSearchResults({ commit }) {
    commit(types.SEARCH_CONVERSATIONS_RESET);
  },
};

export const mutations = {
  [types.SEARCH_CONVERSATIONS_SET](state, records) {
    state.records = records;
  },
  [types.SEARCH_CONVERSATIONS_RESET](state, records) {
    // reset records
    state.contactRecords = [];
    state.conversationRecords = [];
    state.messageRecords = [];

    // reset isHaveMore UI flag
    state.uiFlags.contact.isHaveMore = true
    state.uiFlags.conversation.isHaveMore = true
    state.uiFlags.message.isHaveMore = true
  },
  [types.SEARCH_HAVE_MORE_SET](state, { type, recordsLength }) {
    if (recordsLength < 15) {
      state.uiFlags[type].isHaveMore = false
    }
  },
  [types.CONTACT_SEARCH_SET](state, records) {
    state.contactRecords = [...state.contactRecords, ...records];
  },
  [types.CONVERSATION_SEARCH_SET](state, records) {
    state.conversationRecords = [...state.conversationRecords, ...records];
  },
  [types.MESSAGE_SEARCH_SET](state, records) {
    state.messageRecords = [...state.messageRecords, ...records];
  },
  [types.SEARCH_CONVERSATIONS_SET_UI_FLAG](state, uiFlags) {
    state.uiFlags = { ...state.uiFlags, ...uiFlags };
  },
  [types.FULL_SEARCH_SET_UI_FLAG](state, uiFlags) {
    state.uiFlags = { ...state.uiFlags, ...uiFlags };
  },
  [types.CONTACT_SEARCH_SET_UI_FLAG](state, uiFlags) {
    state.uiFlags.contact = { ...state.uiFlags.contact, ...uiFlags };
  },
  [types.CONVERSATION_SEARCH_SET_UI_FLAG](state, uiFlags) {
    state.uiFlags.conversation = { ...state.uiFlags.conversation, ...uiFlags };
  },
  [types.MESSAGE_SEARCH_SET_UI_FLAG](state, uiFlags) {
    state.uiFlags.message = { ...state.uiFlags.message, ...uiFlags };
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
