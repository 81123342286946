var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banners"},[_c('LimitBanner',{attrs:{"exceeded-active-conversations":_vm.account.exceeded_active_conversations &&
      !_vm.hideWhenSuspended &&
      !_vm.account.spending_grace_macs}}),_vm._v(" "),(
      _vm.account.exceeded_active_conversations &&
      _vm.account.spending_grace_macs &&
      (_vm.account.active_subscription &&
        _vm.account.active_subscription.subscription_type) !== 'grace'
    )?_c('AllBanner'):_vm._e(),_vm._v(" "),_c('RenewalBanner',{attrs:{"end-date":_vm.account.active_subscription && _vm.account.active_subscription.end_date,"condition":!_vm.account.upcoming_subscription &&
      !_vm.account.exceeded_active_conversations &&
      !_vm.account.spending_grace_macs &&
      (_vm.account.active_subscription &&
        _vm.account.active_subscription.subscription_type) !== 'grace'}}),_vm._v(" "),_c('QuotaRenewalBanner',{attrs:{"end-date":_vm.account.active_subscription && _vm.account.active_subscription.end_date,"condition":!_vm.account.upcoming_subscription &&
      _vm.account.exceeded_active_conversations &&
      _vm.account.spending_grace_macs &&
      (_vm.account.active_subscription &&
        _vm.account.active_subscription.subscription_type) !== 'grace'}}),_vm._v(" "),(
      !_vm.account.exceeded_active_conversations &&
      !_vm.account.spending_grace_macs &&
      (_vm.account.active_subscription &&
        _vm.account.active_subscription.subscription_type) === 'grace'
    )?_c('AllBannerInGrace',{attrs:{"reminderDays":_vm.account.active_subscription &&
      _vm.account.active_subscription.grace_remaining_days}}):_vm._e(),_vm._v(" "),(
      _vm.account.exceeded_active_conversations &&
      _vm.account.spending_grace_macs &&
      (_vm.account.active_subscription &&
        _vm.account.active_subscription.subscription_type) === 'grace'
    )?_c('AllTrueInGrace',{attrs:{"reminderDays":_vm.account.active_subscription &&
      _vm.account.active_subscription.grace_remaining_days}}):_vm._e(),_vm._v(" "),_c('SpendingBanner',{attrs:{"spendingGraceMacs":!_vm.account.exceeded_active_conversations && _vm.account.spending_grace_macs,"limits":_vm.account.limits}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }