<template>
  <div
    class="notification"
    :class="{ hidden: notification.hidden }"
    @click="openConversation(notification)"
  >
    <div class="notification__img">
      <thumbnail
        :src="notification.primary_actor.meta.sender.thumbnail"
        size="40px"
        :username="notification.primary_actor.meta.sender.name"
      />
    </div>
    <div class="notification__content">
      <h6>{{ notification.primary_actor.meta.sender.name }}</h6>
      <p>
        {{ notification.push_message_title }}
      </p>
    </div>
    <button
      class="notification__close"
      @click="deleteNotification(notification.id)"
    >
      <i class="fa-solid fa-x"></i>
    </button>
  </div>
</template>

<script>
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';

export default {
  components: {
    Thumbnail,
  },
  props: {
    notification: {
      type: Object,
      default: {},
      required: true,
    },
  },
  methods: {
    deleteNotification(id) {
      this.$store.dispatch('notifications/deleteFloatingNotification', id);
    },
    openConversation(notification) {
      const {
        primary_actor_id: primaryActorId,
        primary_actor_type: primaryActorType,
        primary_actor: { id: conversationId },
      } = notification;

      this.$store.dispatch('notifications/update', {
        primaryActorId,
        primaryActorType,
        unreadCount: this.$store.getters['notifications/getMeta'].unreadCount,
        notificationId: notification.id,
      });

      if (
        this.$route.name !== 'inbox_conversation' &&
        this.$route.params.conversation_id !== conversationId
      ) {
        this.$router.push({
          name: 'inbox_conversation',
          params: { conversation_id: conversationId },
        });
      }

      this.$store.dispatch(
        'notifications/deleteFloatingNotification',
        notification.id
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.notification {
  display: flex;
  gap: 16px;
  width: 350px;
  border-radius: 8px;
  background-color: var(--g-50);
  margin-bottom: 10px;
  padding: 12px 16px;
  border: 1px solid var(--g-500);
  animation: slideIn 0.5s forwards;
  cursor: pointer;

  &__img {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
  }

  &__content {
    flex-grow: 1;
    h6 {
      font-size: 16px;
      font-weight: 600;
      color: var(--b-900);
      margin-bottom: 4px;
    }
    p {
      font-size: 12px;
      font-weight: 400;
      color: var(--b-500);
      margin: 0;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      line-clamp: 4;
      -webkit-box-orient: vertical;
      word-break: break-word;
    }
  }

  &__close {
    position: absolute;
    top: 12px;
    right: 12px;
    border: 1px solid var(--g-500);
    border-radius: 50%;
    width: 16px;
    height: 16px;
    color: var(--g-500);
    font-size: 8px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    * {
      font-weight: bold;
    }
  }
}

.hidden {
  animation: slideOut 0.5s forwards;
}

.app-rtl--wrapper {
  .notification {
    animation-name: slideInAr;

    &__close {
      right: unset;
      left: 12px;
    }
  }

  .hidden {
    animation-name: slideOutAr;
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOuAr {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes slideInAr {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
</style>
