export const BUS_EVENTS = {
  SET_TWEET_REPLY: 'SET_TWEET_REPLY',
  SHOW_ALERT: 'SHOW_ALERT',
  START_NEW_CONVERSATION: 'START_NEW_CONVERSATION',
  FOCUS_CUSTOM_ATTRIBUTE: 'FOCUS_CUSTOM_ATTRIBUTE',
  SCROLL_TO_MESSAGE: 'SCROLL_TO_MESSAGE',
  TOGGLE_SIDEMENU: 'TOGGLE_SIDEMENU',
  ON_MESSAGE_LIST_SCROLL: 'ON_MESSAGE_LIST_SCROLL',
  WEBSOCKET_DISCONNECT: 'WEBSOCKET_DISCONNECT',
  WEBSOCKET_CONNECTED: 'WEBSOCKET_CONNECTED',
  SHOW_TOAST: 'newToastMessage',
  NEW_CONVERSATION_MODAL: 'newConversationModal',
};
