/* global axios */
import ApiClient from './ApiClient';

export const buildContactParams = (page, sortAttr, label, search) => {
  let params = `include_contact_inboxes=false&page=${page}&sort=${sortAttr}`;
  if (search) {
    params = `${params}&q=${search}`;
  }
  if (label) {
    params = `${params}&labels[]=${label}`;
  }
  return params;
};

class ContactAPI extends ApiClient {
  constructor() {
    super('contacts', { accountScoped: true });
  }

  get(page, sortAttr = 'name', label = '', queryPayload) {
    let requestURL = `${this.url}?${buildContactParams(
      page,
      sortAttr,
      label,
      ''
    )}`;
    return axios.get(requestURL, queryPayload);
  }

  blockContact(payload) {
    return axios.post(
      `/api/v3/accounts/${this.accountIdFromRoute}/contacts/${payload.contactId}/toggle_block`,
      {
        blocking_reason: payload.reason,
      }
    );
  }

  getConversations(contactId) {
    return axios.get(`${this.url}/${contactId}/conversations`);
  }

  getContactableInboxes(contactId) {
    return axios.get(`${this.url}/${contactId}/contactable_inboxes`);
  }

  getContactLabels(contactId) {
    return axios.get(`${this.url}/${contactId}/labels`);
  }

  updateContactLabels(contactId, labels) {
    return axios.post(`${this.url}/${contactId}/labels`, { labels });
  }

  search(search = '', page = 1, sortAttr = 'name', label = '') {
    let requestURL = `${this.url}/search?${buildContactParams(
      page,
      sortAttr,
      label,
      search
    )}`;
    return axios.get(requestURL);
  }

  filter(page = 1, sortAttr = 'name', queryPayload, labels) {
    let requestURL = `${this.url}/filter?${buildContactParams(page, sortAttr)}`;
    return axios.post(requestURL, {
      payload: queryPayload.payload,
      labels,
    });
  }

  importContacts(importFormData) {
    return axios.post(`${this.url}/import`, importFormData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  importContactsStatus() {
    return axios.get(`${this.url}/import_status`);
  }
  confirmImport() {
    return axios.post(`${this.url}/import_process`);
  }
  cancelImport() {
    return axios.post(`${this.url}/import_cancel`);
  }

  destroyCustomAttributes(contactId, customAttributes) {
    return axios.post(`${this.url}/${contactId}/destroy_custom_attributes`, {
      custom_attributes: customAttributes,
    });
  }

  destroyAvatar(contactId) {
    return axios.delete(`${this.url}/${contactId}/avatar`);
  }

  exportContacts(params) {
    return axios.post(`${this.url}/export`, params);
  }
  bulkActions(params) {
    return axios.post(
      `/api/v3/accounts/${this.accountIdFromRoute}/contacts/bulk_actions`,
      params
    );
  }
}

export default new ContactAPI();
