// this 
import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import FacebookAPI from '../../api/facebook';

export const state = {
  records: [],
  postsPayload: {},
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isDeleting: false,
  },
};

export const getters = {
  getPages(_state) {
    return _state.records;
  },
  getPostsPayload(_state) {
    return _state.postsPayload;
  },
  getUIFlags(_state) {
    return _state.uiFlags;
  },
};

export const actions = {
  getPages: async ({ commit }, requestObj) => {
    commit(types.SET_FACEBOOK_UI_FLAG, { isFetching: true });
    try {
      const response = await FacebookAPI.getPages(requestObj);
      commit(types.SET_FACEBOOK_PAGES, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_FACEBOOK_UI_FLAG, { isFetching: false });
    }
  },

  getPosts: async ({ commit }, getPostsRequestObj) => {
    commit(types.SET_FACEBOOK_UI_FLAG, { isFetching: true });
    try {
      const response = await FacebookAPI.getPosts(getPostsRequestObj);
      commit(types.SET_FACEBOOK_POSTS, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_FACEBOOK_UI_FLAG, { isFetching: false });
    }
  },

  clearPosts: ({commit}) => {
    commit(types.CLEAR_FACEBOOK_POSTS);
  }
};

export const mutations = {
  [types.SET_FACEBOOK_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.SET_FACEBOOK_PAGES]: MutationHelpers.set,
  [types.SET_FACEBOOK_POSTS](_state, data) {
    let allPosts = _state.postsPayload.posts
      ? [..._state.postsPayload.posts, ...data.posts]
      : [...data.posts]

    _state.postsPayload = data;
    _state.postsPayload.posts = allPosts;

  },
  [types.CLEAR_FACEBOOK_POSTS](_state) {
    _state.postsPayload = {};
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
