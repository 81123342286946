/* eslint no-console: 0 */
import * as types from '../../../mutation-types';
import OutGoingMessageList from '../../../../api/reports/message/outGoingMessageList';

const state = {
  isFetching: false,
  messages: {
    data: [],
    meta: {},
  },
  download: {
    isLoading: false,
  },
};

const getters = {
  getMessageIncoming(_state) {
    return _state.messages;
  },
  isLoading(_state) {
    return _state.isFetching;
  },
  getDownloaded(_state) {
    return _state.download.isLoading;
  },
};

export const actions = {
  get: async function getMessageIncoming({ commit }, prams) {
    commit(types.default.TOGGLE_MESSAGE_OUT_GOING, { isFetching: true });
    try {
      const response = await OutGoingMessageList.get(prams);
      commit(types.default.SET_MESSAGE_OUT_GOING, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.default.TOGGLE_MESSAGE_OUT_GOING, { isFetching: false });
    }
  },
  download: async function downloadMessageIncoming({ commit }, prams) {
    commit(types.default.TOGGLE_DOWNLOAD_MESSAGE_OUT_GOING, {
      isFetching: true,
    });
    try {
      const response = await OutGoingMessageList.download(prams);
      commit(types.default.SET_DOWNLOAD_MESSAGE_OUT_GOING, response);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.default.TOGGLE_DOWNLOAD_MESSAGE_OUT_GOING, {
        isFetching: false,
      });
    }
  },
};

const mutations = {
  [types.default.SET_MESSAGE_OUT_GOING](_state, IncomingMessages) {
    _state.messages.data = IncomingMessages.payload;
    _state.messages.meta = IncomingMessages.meta;
  },
  [types.default.TOGGLE_MESSAGE_OUT_GOING](_state, flag) {
    _state.isFetching = flag.isFetching;
  },

  [types.default.SET_DOWNLOAD_MESSAGE_OUT_GOING](_state, download, flag) {
    if (
      download.status === Number(200) &&
      download.statusText === String('OK')
    ) {
      _state.download.isLoading = flag.isFetching;
    }
  },
  [types.default.TOGGLE_DOWNLOAD_MESSAGE_OUT_GOING](_state, flag) {
    _state.download.isLoading = flag.isFetching;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
