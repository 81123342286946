/* global axios */
import ApiClient from '../../ApiClient';
const getTimeOffset = () => -new Date().getTimezoneOffset() / 60;

class IncomingMessageList extends ApiClient {
  constructor() {
    super('incoming_logs', { accountScoped: true, apiVersion: 'v3' });
  }

  get(params) {
    return axios.get(`${this.url}`, {
      params: {
        since: params.since,
        until: params.until,
        content: params.search,
        inbox_id: params.inbox_id,
        page: params.pageIndex,
        team_id: params.team_id,
        agent_id: params.agent_id,
        timezone_offset: getTimeOffset(),
        sort: params.sort?.order_column,
        direction: params.sort?.order_direction,
      },
    });
  }

  download(params) {
    return axios.get(`${this.url}/download`, {
      params: {
        since: params.since,
        until: params.until,
        content: params.search,
        inbox_id: params.inbox_id,
        page: params.pageIndex,
        team_id: params.team_id,
        agent_id: params.agent_id,
        timezone_offset: getTimeOffset(),
      },
    });
  }
}

export default new IncomingMessageList();
