<template>
  <div>
    <banner
      v-if="shouldShowBeforeSevenDaysBanner && condition"
      class="update-banner"
      color-scheme="primary"
      :banner-message="bannerMessage"
      href-link=""
      :href-link-text="$t('GENERAL_SETTINGS.LEARN_MORE')"
    />
  </div>
</template>
<script>
import Banner from 'dashboard/components/ui/Banner.vue';
import adminMixin from 'dashboard/mixins/isAdmin';

export default {
  components: { Banner },
  mixins: [adminMixin],
  props: ['endDate', 'condition'],
  data() {
    return { userDismissedBanner: false };
  },
  computed: {
    bannerMessage() {
      return this.$t('GENERAL_SETTINGS.QUOTA_END_DATE', {
        days: this.deff(),
      });
    },
    shouldShowBeforeSevenDaysBanner() {
      return (
        new Date().setHours(0, 0, 0, 0) >=
          this.beforeSevenDays.setHours(0, 0, 0, 0) &&
        new Date().setHours(0, 0, 0, 0) <=
          new Date(this.endDate).setHours(0, 0, 0, 0) &&
        this.isAdmin
      );
    },

    beforeSevenDays() {
      var d = new Date(this.endDate);
      d.setDate(d.getDate() - 7);
      return d;
    },
  },
  methods: {
    deff() {
      const date1 = new Date();
      const date2 = new Date(this.endDate);
      const diffTime = date2 - date1;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays > 0 ? diffDays : 0;
    },
  },
};
</script>
