import ApiClient from '../../../../../api/ApiClient';

export default class CustomAPIClient extends ApiClient {
  constructor(resource = 'contact_logs', apiVersion = 'v3') {
    super(resource, { accountScoped: true, apiVersion });
  }

  async getLogs(params = '') {
    let data = await axios.get(this.url);
    return await data.data;
  }

  async get(params = {}) {
    let options = {};
    if (Object.keys(params).length > 0) {
      options.params = { ...params };
    }
    let data = await axios.get(this.url, options);
    return await data.data;
  }

  async post(data = {}) {
    return await axios.post(this.url, data);
  }

  async delete(data = {}) {
    return await axios.delete(this.url, data);
  }
}
