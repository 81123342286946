/* global axios */
import ApiClient from './ApiClient';

const getTimeOffset = () => -new Date().getTimezoneOffset() / 60;

class ReportsConversation extends ApiClient {
  constructor() {
    super('reports', { accountScoped: true, apiVersion: 'v3' });
  }

  getChartData(params) {
    return axios.get(`${this.url}`, {
      params: {
        metric: params.metric,
        since: params.since,
        until: params.until,
        group_by: params.group_by,
        team_id: params.team_id,
        agent_id: params.agent_id,
        note_id: params.note_id,
        group_by_type: params.group_by_type,
        timezone_offset: getTimeOffset(),
      },
    });
  }

  getConversationLogsSummary(params) {
    return axios.get(`${this.url}/conversation_logs_summary`, {
      params: {
        since: params.since,
        until: params.until,
        group_by: params.groupBy,
        timezone_offset: getTimeOffset(),
        team_id: params.team_id,
        agent_id: params.agent_id,
        note_id: params.note_id,
      },
    });
  }

  getOpenedConversationSummary(params) {
    return axios.get(`${this.url}/opened_conversation_summary`, {
      params: {
        since: params.since,
        until: params.until,
        group_by: params.groupBy,
        timezone_offset: getTimeOffset(),
        team_id: params.team_id,
        agent_id: params.agent_id,
        note_id: params.note_id,
      },
    });
  }

  getConversationMetricSummary(params, group_by_type) {
    return axios.get(`${this.url}`, {
      params: {
        metric: params.metric,
        since: params.since,
        until: params.until,
        group_by: params.groupBy,
        team_id: params.team_id,
        agent_id: params.agent_id,
        note_id: params.note_id,
        group_by_type,
        timezone_offset: getTimeOffset(),
      },
    });
  }

  getConversationLogsPerformance(params) {
    return axios.get(`${this.url}/conversation_performance_summary`, {
      params: {
        since: params.since,
        until: params.until,
        group_by: params.groupBy,
        team_id: params.team_id,
        agent_id: params.agent_id,
        note_id: params.note_id,
        page: params.page,
        timezone_offset: getTimeOffset(),
      },
    });
  }

  getConversationMetricPerformance(params) {
    return axios.get(`${this.url}`, {
      params: {
        metric: params.metric,
        since: params.since,
        until: params.until,
        group_by: params.groupBy,
        team_id: params.team_id,
        agent_id: params.agent_id,
        note_id: params.note_id,
        timezone_offset: getTimeOffset(),
      },
    });
  }
}

export default new ReportsConversation();
