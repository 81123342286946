<template>
  <div id="profile-settings-notifications">
    <!-- audio notification -->
    <div class="setting-item">
      <div class="text">
        <h4 class="setting-item__text__title">
          {{ $t('PROFILE_SETTINGS.FORM.AUDIO_NOTIFICATIONS_SECTION.TITLE') }}
        </h4>
        <p class="setting-item__text__help-text">
          {{ $t('PROFILE_SETTINGS.FORM.AUDIO_NOTIFICATIONS_SECTION.NOTE') }}
        </p>
      </div>
      <div class="my-3">
        <!-- alerts events -->
        <div class="notification-items--wrapper">
          <span class="text-block-title notification-label">
            {{
              $t(
                'PROFILE_SETTINGS.FORM.AUDIO_NOTIFICATIONS_SECTION.ALERT_TYPE.TITLE'
              )
            }}
          </span>
          <div class="row">
            <div
              class="col-4"
              v-for="item in audioNotification"
              :key="item.key"
            >
              <input
                v-model="enableAudioAlerts"
                :id="item.key"
                class="notification--checkbox"
                type="radio"
                :value="item.value"
                @input="handleAudioInput"
              />
              <label :for="item.key">
                {{
                  $t(
                    `PROFILE_SETTINGS.FORM.AUDIO_NOTIFICATIONS_SECTION.ALERT_TYPE.${item.translationKey}`
                  )
                }}
              </label>
            </div>
          </div>
        </div>
        <!-- alert tone -->
        <div
          class="notification-items--wrapper d-flex align-items-center gap-3"
        >
          <span class="text-block-title notification-label">
            {{
              $t(
                'PROFILE_SETTINGS.FORM.AUDIO_NOTIFICATIONS_SECTION.DEFAULT_TONE.TITLE'
              )
            }}
          </span>
          <div class="multiselect-wrap--small w-25">
            <multiselect
              v-model="notificationTone"
              track-by="value"
              label="label"
              selected-label
              :options="notificationAlertTones"
              :searchable="false"
              :multiple="false"
              :allow-empty="false"
              @select="handleAudioToneChange"
            />
          </div>
          <div>
            <!-- <select
              v-model="notificationTone"
              class="tone-selector"
              @change="handleAudioToneChange"
            >
              <option
                v-for="tone in notificationAlertTones"
                :key="tone.value"
                :value="tone.value"
              >
                {{ tone.label }}
              </option>
            </select> -->
          </div>
        </div>
        <!-- Alert conditions -->
        <div class="notification-items--wrapper">
          <span class="text-block-title notification-label">
            {{
              $t(
                'PROFILE_SETTINGS.FORM.AUDIO_NOTIFICATIONS_SECTION.CONDITIONS.TITLE'
              )
            }}
          </span>
          <div class="row">
            <div class="col-6">
              <input
                id="audio_alert_when_tab_is_inactive"
                v-model="playAudioWhenTabIsInactive"
                class="notification--checkbox"
                type="checkbox"
                value="tab_is_inactive"
                @input="handleAudioAlertConditions"
              />
              <label for="audio_alert_when_tab_is_inactive">
                {{
                  $t(
                    'PROFILE_SETTINGS.FORM.AUDIO_NOTIFICATIONS_SECTION.CONDITIONS.CONDITION_ONE'
                  )
                }}
              </label>
            </div>
            <div class="col-6">
              <input
                id="audio_alert_until_all_conversations_are_read"
                v-model="alertIfUnreadConversationExist"
                class="notification--checkbox"
                type="checkbox"
                value="conversations_are_read"
                @input="handleAudioAlertConditions"
              />
              <label for="audio_alert_until_all_conversations_are_read">
                {{
                  $t(
                    'PROFILE_SETTINGS.FORM.AUDIO_NOTIFICATIONS_SECTION.CONDITIONS.CONDITION_TWO'
                  )
                }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Email notifications -->
    <div class="setting-item">
      <div class="text">
        <h4 class="setting-item__text__title">
          {{ $t('PROFILE_SETTINGS.FORM.EMAIL_NOTIFICATIONS_SECTION.TITLE') }}
        </h4>
        <p class="setting-item__text__help-text">
          {{ $t('PROFILE_SETTINGS.FORM.EMAIL_NOTIFICATIONS_SECTION.NOTE') }}
        </p>
      </div>
      <div class="mt-4">
        <div class="row g-4">
          <div
            class="col-6"
            v-for="item in notification"
            :key="`email_${item.value}`"
          >
            <label>
              <input
                v-model="selectedEmailFlags"
                class="notification--checkbox"
                type="checkbox"
                :value="`email_${item.value}`"
                @input="handleEmailInput"
              />
              {{
                $t(
                  `PROFILE_SETTINGS.FORM.EMAIL_NOTIFICATIONS_SECTION.${item.translationKey}`
                )
              }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <!-- Push notification -->
    <div v-if="vapidPublicKey && hasPushAPISupport" class="setting-item">
      <div class="text">
        <h4 class="setting-item__text__title">
          {{ $t('PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.TITLE') }}
        </h4>
        <p class="setting-item__text__help-text">
          {{ $t('PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.NOTE') }}
        </p>
      </div>
      <div>
        <p v-if="hasEnabledPushPermissions">
          {{
            $t(
              'PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.HAS_ENABLED_PUSH'
            )
          }}
        </p>
        <div v-else class="push-notification--button">
          <woot-submit-button
            :button-text="
              $t(
                'PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.REQUEST_PUSH'
              )
            "
            class="button nice small"
            type="button"
            @click="onRequestPermissions"
          />
        </div>
        <div class="mt-4">
          <div class="row g-4">
            <div
              class="col-6"
              v-for="item in notification"
              :key="`push_${item.value}`"
            >
              <label>
                <input
                  v-model="selectedPushFlags"
                  class="notification--checkbox"
                  type="checkbox"
                  :value="`push_${item.value}`"
                  @input="handlePushInput"
                />
                {{
                  $t(
                    `PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.${item.translationKey}`
                  )
                }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import configMixin from 'shared/mixins/configMixin';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import {
  hasPushPermissions,
  requestPushPermissions,
  verifyServiceWorkerExistence,
} from '../../../../helper/pushHelper';

export default {
  mixins: [alertMixin, configMixin, uiSettingsMixin],
  data() {
    return {
      selectedEmailFlags: [],
      selectedPushFlags: [],
      enableAudioAlerts: false,
      hasEnabledPushPermissions: false,
      playAudioWhenTabIsInactive: false,
      alertIfUnreadConversationExist: false,
      notificationTone: '',
      notificationAlertTones: [
        {
          value: 'ding',
          label: 'Ding',
        },
        {
          value: 'bell',
          label: 'Bell',
        },
      ],
      notification: [
        {
          value: 'conversation_creation',
          translationKey: 'CONVERSATION_CREATION',
        },
        {
          value: 'conversation_assignment',
          translationKey: 'CONVERSATION_ASSIGNMENT',
        },
        {
          value: 'conversation_mention',
          translationKey: 'CONVERSATION_MENTION',
        },
        {
          value: 'assigned_conversation_new_message',
          translationKey: 'ASSIGNED_CONVERSATION_NEW_MESSAGE',
        },
        {
          value: 'participating_conversation_new_message',
          translationKey: 'PARTICIPATING_CONVERSATION_NEW_MESSAGE',
        },
        {
          value: 'contacts_import_completed',
          translationKey: 'CONTACTS_IMPORT_COMPLETED',
        },
      ],
      audioNotification: [
        {
          value: 'none',
          key: 'audio_enable_alert_none',
          translationKey: 'NONE',
        },
        {
          value: 'mine',
          key: 'audio_enable_alert_mine',
          translationKey: 'ASSIGNED',
        },
        {
          value: 'all',
          key: 'audio_enable_alert_all',
          translationKey: 'ALL_CONVERSATIONS',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      emailFlags: 'userNotificationSettings/getSelectedEmailFlags',
      pushFlags: 'userNotificationSettings/getSelectedPushFlags',
      uiSettings: 'getUISettings',
    }),
    hasPushAPISupport() {
      return !!('Notification' in window);
    },
  },
  watch: {
    emailFlags(value) {
      this.selectedEmailFlags = value;
    },
    pushFlags(value) {
      this.selectedPushFlags = value;
    },
    uiSettings(value) {
      this.notificationUISettings(value);
    },
  },
  mounted() {
    if (hasPushPermissions()) {
      this.getPushSubscription();
    }
    this.notificationUISettings(this.uiSettings);
    this.$store.dispatch('userNotificationSettings/get');
  },
  methods: {
    notificationUISettings(uiSettings) {
      const {
        enable_audio_alerts: enableAudio = false,
        always_play_audio_alert: alwaysPlayAudioAlert,
        alert_if_unread_assigned_conversation_exist:
          alertIfUnreadConversationExist,
        notification_tone: notificationTone,
      } = uiSettings;
      this.enableAudioAlerts = enableAudio;
      this.playAudioWhenTabIsInactive = !alwaysPlayAudioAlert;
      this.alertIfUnreadConversationExist = alertIfUnreadConversationExist;
      this.notificationTone = this.notificationAlertTones.find(
        tone => tone.value == notificationTone
      ) || { value: 'ding', label: 'Ding' };
    },
    onRegistrationSuccess() {
      this.hasEnabledPushPermissions = true;
    },
    onRequestPermissions() {
      requestPushPermissions({
        onSuccess: this.onRegistrationSuccess,
      });
    },
    getPushSubscription() {
      verifyServiceWorkerExistence(registration =>
        registration.pushManager
          .getSubscription()
          .then(subscription => {
            if (!subscription) {
              this.hasEnabledPushPermissions = false;
            } else {
              this.hasEnabledPushPermissions = true;
            }
          })
          // eslint-disable-next-line no-console
          .catch(error => console.log(error))
      );
    },
    async updateNotificationSettings() {
      try {
        this.$store.dispatch('userNotificationSettings/update', {
          selectedEmailFlags: this.selectedEmailFlags,
          selectedPushFlags: this.selectedPushFlags,
        });
        this.showAlert(this.$t('PROFILE_SETTINGS.FORM.API.UPDATE_SUCCESS'));
      } catch (error) {
        this.showAlert(
          this.$t('PROFILE_SETTINGS.FORM.API.UPDATE_ERROR'),
          'error'
        );
      }
    },
    handleEmailInput(e) {
      this.selectedEmailFlags = this.toggleInput(
        this.selectedEmailFlags,
        e.target.value
      );

      this.updateNotificationSettings();
    },
    handlePushInput(e) {
      this.selectedPushFlags = this.toggleInput(
        this.selectedPushFlags,
        e.target.value
      );

      this.updateNotificationSettings();
    },
    handleAudioInput(e) {
      this.enableAudioAlerts = e.target.value;
      this.updateUISettings({
        enable_audio_alerts: this.enableAudioAlerts,
      });
      this.showAlert(this.$t('PROFILE_SETTINGS.FORM.API.UPDATE_SUCCESS'));
    },
    handleAudioAlertConditions(e) {
      let condition = e.target.value;
      if (condition === 'tab_is_inactive') {
        this.updateUISettings({
          always_play_audio_alert: !e.target.checked,
        });
      } else if (condition === 'conversations_are_read') {
        this.updateUISettings({
          alert_if_unread_assigned_conversation_exist: e.target.checked,
        });
      }
      this.showAlert(this.$t('PROFILE_SETTINGS.FORM.API.UPDATE_SUCCESS'));
    },
    handleAudioToneChange(selectedOption) {
      this.updateUISettings({ notification_tone: selectedOption.value });
      this.showAlert(this.$t('PROFILE_SETTINGS.FORM.API.UPDATE_SUCCESS'));
    },
    toggleInput(selected, current) {
      if (selected.includes(current)) {
        const newSelectedFlags = selected.filter(flag => flag !== current);
        return newSelectedFlags;
      }
      return [...selected, current];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables.scss';

.notification--checkbox {
  font-size: $font-size-large;
}

.push-notification--button {
  margin-bottom: var(--space-one);
}

.notification-items--wrapper {
  margin: 8px 0;
}

.notification-label {
  display: flex;
  font-weight: var(--font-weight-bold);
  margin-bottom: var(--space-small);
}

.tone-selector {
  height: var(--space-large);
  padding-bottom: var(--space-micro);
  padding-top: var(--space-micro);
  width: var(--space-mega);
}
.setting-item {
  padding: 24px 16px;
  border-radius: 16px;
  margin: 24px 0;
  background-color: var(--s-50);
  &__text {
    &__title {
      color: var(--b-700);
      font-weight: 600;
      font-size: 18px;
    }
    &__help-text {
      color: var(--b-500);
      font-size: 14px;
      font-weight: 400;
    }
  }
}
</style>
