import { render, staticRenderFns } from "./CustomNotificationWidget.vue?vue&type=template&id=68cf0f12&scoped=true&"
import script from "./CustomNotificationWidget.vue?vue&type=script&lang=js&"
export * from "./CustomNotificationWidget.vue?vue&type=script&lang=js&"
import style0 from "./CustomNotificationWidget.vue?vue&type=style&index=0&id=68cf0f12&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68cf0f12",
  null
  
)

export default component.exports