import { FEATURE_FLAGS } from '../../../../featureFlags';
import { frontendURL } from '../../../../helper/URLHelper';

const primaryMenuItems = accountId => [
  {
    icon: 'speedometer',
    key: 'overview',
    label: 'DASHBOARDOVERVIEW',
    toState: frontendURL(`accounts/${accountId}/overview`),
    toStateName: 'overview',
    permission: 'show_dashboard',
  },
  {
    icon: 'chat',
    key: 'conversations',
    label: 'CONVERSATIONS',
    toState: frontendURL(`accounts/${accountId}/dashboard`),
    toStateName: 'home',
  },
  {
    icon: 'book-contacts',
    key: 'contacts',
    label: 'CONTACTS',
    featureFlag: FEATURE_FLAGS.CRM,
    toState: frontendURL(`accounts/${accountId}/contacts`),
    toStateName: 'contacts_dashboard',
    permission: 'show_contacts',
  },
  {
    icon: 'megaphone',
    key: 'campaigns',
    label: 'CAMPAIGNS',
    featureFlag: FEATURE_FLAGS.CAMPAIGNS,
    toState: frontendURL(`accounts/${accountId}/campaigns`),
    toStateName: 'whatsapp',
    permission: 'show_campaigns',
  },
  {
    icon: 'workflow',
    label: 'WORKFLOWS',
    hasSubMenu: false,
    toState: frontendURL(`accounts/${accountId}/workflows`),
    toStateName: 'settings_workflows',
    permission: 'show_workflows',
  },
  {
    icon: 'arrow-trending-lines',
    key: 'reports',
    label: 'REPORTS',
    featureFlag: FEATURE_FLAGS.REPORTS,
    toState: frontendURL(`accounts/${accountId}/reports`),
    toStateName: 'conversation_reports',
    permission: 'show_reports',
  },
  {
    icon: 'settings',
    key: 'settings',
    label: 'SETTINGS',
    toState: frontendURL(`accounts/${accountId}/settings`),
    toStateName: 'settings_home',
  },
];

export default primaryMenuItems;
