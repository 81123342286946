/* global axios */
import ApiClient from './ApiClient';

class BlockListApi extends ApiClient {
  constructor() {
    super('contacts', { accountScoped: true, apiVersion: 'v3' });
  }

  getBlockList(params) {
    return axios.get(`${this.url}/blocked`, {
      params,
    });
  }

  unblockAll(data) {
    return axios.post(`${this.url}/bulk_actions`, {
      ids: data.ids,
      type: 'unblock',
    });
  }

  unblockContact(payload) {
    return axios.post(`${this.url}/${payload.id}/toggle_block`);
  }
}

export default new BlockListApi();
