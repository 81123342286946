import ApiClient from './ApiClient';

class facebook extends ApiClient {
  constructor() {
    super('facebook_pages', { accountScoped: true });
  }

  getPages({ page_type, inbox_id }) {
    return axios.get(`${this.url}/${page_type == 'instagram' ? 'instagram' : ''}`, {
      params: {
        inbox_id
      }
    });
  }

  getPosts({ pageId, changePage, postType, since, until, pageType }) {
    return axios.get(`${this.url}/${pageId}/${pageType == "instagram" ? 'ig_media' : 'posts'}`, {
      params: {
        limit: 9,
        ...changePage, // incase in future using {after: || before:}
        type: postType,
        since,
        until
      },
    });
  }
}

export default new facebook();
