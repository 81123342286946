import ApiClient from './ApiClient';

class Salla extends ApiClient {
  constructor() {
    super('subscriptions', { accountScoped: true, apiVersion: 'v3' });
  }

  getSubscriptionInfo() {
    return axios.get(`${this.url}/info`);
  }
  getAddons(params) {
    return axios.get(`${this.url}/${params.subscriptionId}/addons`, {
      params: {
        page: params.page,
        limit: params.limit
      }
    }
    );
  }
  getSubscriptions(params) {
    return axios.get(`${this.url}`, {
      params: {
        page: params.page,
        limit: params.limit
      }
    });
  }
}

export default new Salla();
