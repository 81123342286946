<template>
  <banner
    class="update-banner"
    color-scheme="danger"
    :banner-message="bannerMessage"
    href-link="https://api.whatsapp.com/send?phone=966920007031"
    href-link-text="(Whatsapp)"
  />
</template>
<script>
import Banner from 'dashboard/components/ui/Banner.vue';
import adminMixin from 'dashboard/mixins/isAdmin';

export default {
  components: { Banner },
  mixins: [adminMixin],
  props: {
    exceededActiveConversations: {
      type: Boolean,
      default: false,
    },
    reminderDays: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return { userDismissedBanner: false };
  },
  computed: {
    bannerMessage() {
      return this.$t('GENERAL_SETTINGS.ALL_PLAN_TRUE_IN_GRACE', {
        days: this.reminderDays,
      });
    },
  },
};
</script>
