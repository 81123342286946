/* eslint no-console: 0 */
import * as types from '../../mutation-types';
import ReportBroadcast from '../../../api/broadcast/reportBroadcast';

const state = {
  isLoading: false,
  broadcast: {
    data: [],
    meta: {},
  },
  total: {},
};

const getters = {
  getReportBroadcast(_state) {
    return _state.total;
  },
  uIFlags(_state) {
    return _state.isLoading;
  },
};

export const actions = {
  fetchReportBroadcast: async function fetchReportBroadcast(
    { commit },
    params
  ) {
    commit(types.default.TOGGLE_BROADCAST_REPORTS, { isFetching: true });
    try {
      const response = await ReportBroadcast.get(params);
      commit(types.default.SET_BROADCAST_REPORT, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.default.TOGGLE_BROADCAST_REPORTS, { isFetching: false });
    }
  },
};

const mutations = {
  [types.default.SET_BROADCAST_REPORT](_state, data) {
    _state.total = data;
  },
  [types.default.TOGGLE_BROADCAST_REPORTS](_state, flag) {
    _state.isLoading = flag;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
