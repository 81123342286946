import types from '../mutation-types';
import LogsReports from '../../api/logsReports';

export const state = {
  assignment_logs: {},
  comment_logs: {},
  uiFlags: {
    isFetchingAssignmentLogs: false,
    isFetchingCommentLogs: false,
  },
};

export const getters = {
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  getAssignmentLogs(_state) {
    return _state.assignment_logs;
  },
  getCommentLogs(_state) {
    return _state.comment_logs;
  },
};
export const actions = {
  async getAssignmentLogs({ commit }, params) {
    commit(types.SET_LOGS_REPORTS_UI_FLAG, {
      isFetchingAssignmentLogs: true,
    });
    try {
      const { data } = await LogsReports.getAssignmentLogs(params);
      commit(types.SET_ASSIGNMENT_LOGS, data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_LOGS_REPORTS_UI_FLAG, {
        isFetchingAssignmentLogs: false,
      });
    }
  },
  async getCommentLogs({ commit }, params) {
    commit(types.SET_LOGS_REPORTS_UI_FLAG, {
      isFetchingCommentLogs: true,
    });
    try {
      const { data } = await LogsReports.getCommentLogs(params);
      commit(types.SET_COMMENT_LOGS, data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_LOGS_REPORTS_UI_FLAG, {
        isFetchingCommentLogs: false,
      });
    }
  },
};

export const mutations = {
  [types.SET_LOGS_REPORTS_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
  [types.SET_ASSIGNMENT_LOGS](_state, data) {
    _state.assignment_logs = { ...data };
  },
  [types.SET_COMMENT_LOGS](_state, data) {
    _state.comment_logs = { ...data };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
