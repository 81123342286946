import { frontendURL } from '../../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/roles'),
      roles: ['administrator'],
      component: () => import('../Wrapper'),
      props: params => {
        const showBackButton = params.name !== 'roles_settings_index';
        return {
          headerTitle: 'ROLES_MGMT.HEADER',
          icon: 'people',
          showBackButton,
        };
      },
      children: [
        {
          path: '',
          name: 'roles_settings_index',
          component: () => import('./Index'),
          roles: ['administrator'],
          meta: {
            permission: 'show_roles',
          },
        },
        {
          path: 'add',
          name: 'roles_settings_add',
          component: () => import('./AddRolePage'),
          roles: ['administrator'],
        },
        {
          path: 'edit/:roleId',
          name: 'roles_settings_edit',
          component: () => import('./EditRolePage'),
          roles: ['administrator'],
          props:true
        },
      ],
    },
  ],
};
