export const getCurrentAccount = ({ accounts } = {}, accountId) => {
  return accounts.find(account => account.id === accountId);
};

export const getUserRole = ({ accounts } = {}, accountId) => {
  const currentAccount = getCurrentAccount({ accounts }, accountId) || {};
  return currentAccount.role || null;
};

export const routeIsAccessibleFor = (route, role, roleWiseRoutes) => {
  return roleWiseRoutes[role].includes(route);
};

const routeIsAccessibleForUser = (route, user, accountId) => {
  return route.meta.permission
    ? userHasPermission(user, accountId, route.meta.permission)
    : true;
};

const validateActiveAccountRoutes = (to, user, roleWiseRoutes) => {
  // If the current account is active, then check for the route permissions
  const accountDashboardURL = `accounts/${to.params.accountId}/dashboard`;

  // If the user is trying to access suspended route, redirect them to dashboard
  if (to.name === 'account_suspended' || to.name === 'account_inactivated') {
    return accountDashboardURL;
  }

  // const userRole = getUserRole(user, Number(to.params.accountId));
  // const isAccessible = routeIsAccessibleFor(to.name, userRole, roleWiseRoutes);
  const isAccessible = routeIsAccessibleForUser(
    to,
    user,
    Number(to.params.accountId)
  );
  // If the route is not accessible for the user, return to dashboard screen
  return isAccessible ? null : accountDashboardURL;
};

export const validateLoggedInRoutes = (to, user, roleWiseRoutes) => {
  const currentAccount = getCurrentAccount(user, Number(to.params.accountId));

  if (!currentAccount && user.account_id) {
    return `accounts/${user.account_id}/${fallbackPath(user, user.account_id)}`;
  }

  // If current account is missing, either user does not have
  // access to the account or the account is deleted, return to login screen
  if (!currentAccount) {
    return `login`;
  }

  if (!to.name) {
    return `accounts/${to.params.accountId}/${fallbackPath(
      user,
      to.params.accountId
    )}`;
  }

  const isCurrentAccountActive = currentAccount.status === 'active';
  const isAgentActive = currentAccount.agent_status === 'active';

  if (isCurrentAccountActive && isAgentActive) {
    return validateActiveAccountRoutes(to, user, roleWiseRoutes);
  }

  // If the current account is not active, then redirect the user to the suspended screen
  if (!isCurrentAccountActive && to.name !== 'account_suspended') {
    return `accounts/${to.params.accountId}/suspended`;
  }

  if (!isAgentActive && to.name !== 'account_inactivated') {
    return `accounts/${to.params.accountId}/inactivated`;
  }

  // Proceed to the route if none of the above conditions are met
  return null;
};

export const userHasPermission = ({ accounts } = {}, accountId, permission) => {
  const currentUserAccount = accounts.find(account => account.id === accountId);

  if (currentUserAccount?.role === 'administrator') {
    return true;
  }

  return currentUserAccount
    ? currentUserAccount.permissions.includes(permission)
    : false;
};

const fallbackPath = (user, accountId) => {
  return userHasPermission(user, accountId, 'show_dashboard')
    ? 'overview'
    : 'dashboard';
};
