<template>
  <transition name="menu-slide">
    <div
      v-if="show"
      v-on-clickaway="toggleFunc"
      class="help-menu options-menu dropdown-pane"
      :class="{ 'dropdown-pane--open': show }"
    >
      <woot-dropdown-menu>
        <woot-dropdown-item>
          <a
            href="https://docs.bevatel.com"
            class="help-link"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            <img src="/dashboard/images/help-menu/guide.svg" alt="guide" />
            {{ $t('HELP_CENTER_MENU.GUIDE') }}
          </a>
        </woot-dropdown-item>
        <woot-dropdown-item>
          <a
            href="https://api.whatsapp.com/send?phone=966920007031&text=I%20need%20support%20on%20Bevatel%20Business%20Chatdescription"
            class="help-link"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            <img src="/dashboard/images/help-menu/support.svg" alt="support" />
            {{ $t('HELP_CENTER_MENU.SUPPORT') }}
          </a>
        </woot-dropdown-item>
        <woot-dropdown-item>
          <a
            href="https://bevatelbusinesschat.canny.io/feature-requests"
            class="help-link"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            <img src="/dashboard/images/help-menu/feature.svg" alt="feature" />
            {{ $t('HELP_CENTER_MENU.FEATURE') }}
          </a>
        </woot-dropdown-item>
        <woot-dropdown-item>
          <a
            href="https://bevatelbusinesschat.canny.io/bugs"
            class="help-link"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            <img src="/dashboard/images/help-menu/bug.svg" alt="bug" />
            {{ $t('HELP_CENTER_MENU.BUG') }}
          </a>
        </woot-dropdown-item>
        <woot-dropdown-item>
          <a
            class="help-link"
            rel="noopener noreferrer nofollow"
            target="_blank"
            @click="handleShortcutModal()"
          >
            <img src="/dashboard/images/help-menu/keyboard.svg" alt="" />
            {{ $t('SIDEBAR_ITEMS.KEYBOARD_SHORTCUTS') }}
          </a>
        </woot-dropdown-item>
        <woot-dropdown-item>
          <a
            class="help-link"
            rel="noopener noreferrer nofollow"
            target="_blank"
            @click="handleWidgetModal()"
          >
            <img src="/dashboard/images/help-menu/widget.svg" alt="" />
            {{ $t('SIDEBAR_ITEMS.CONTACT_WIDGET') }}
          </a>
        </woot-dropdown-item>
      </woot-dropdown-menu>
    </div>
  </transition>
</template>

<script>
import rtlMixin from 'shared/mixins/rtlMixin';
import { mixin as clickaway } from 'vue-clickaway';
export default {
  mixins: [clickaway, rtlMixin],
  props: {
    toggleFunc: {
      type: Function,
      required: true,
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleShortcutModal() {
      this.toggleFunc();
      this.$emit('key-shortcut-modal');
    },
    handleWidgetModal() {
      window.$socialApp.toggle('show');
      window.$socialApp.toggleBubbleVisibility('show');
      const wootWidgetHolder = document.querySelector('.woot-widget-holder');
      wootWidgetHolder.classList.add('woot-widget--without-bubble');
      if (this.isRTLView) {
        wootWidgetHolder?.classList.add('woot-elements--right');
        wootWidgetHolder?.classList.remove('woot-elements--left');
      } else {
        wootWidgetHolder?.classList.remove('woot-elements--right');
        wootWidgetHolder?.classList.add('woot-elements--left');
      }
      // this.toggleFunc();
    },
  },
};
</script>

<style lang="scss" scoped></style>
