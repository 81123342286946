<template>
  <div class="wizard-body">
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.SMS.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.SMS.DESC')"
    />
    <div class="medium-8 columns">
      <label class="relative" style="padding: 0 0.75rem">
        {{ $t('INBOX_MGMT.ADD.SMS.PROVIDERS.LABEL') }}
        <div class="multiselect-wrap--small">
          <multiselect
            v-model="provider"
            :options="providers"
            track-by="value"
            label="name"
            :multiple="false"
            :searchable="false"
            :hide-selected="false"
            :allow-empty="false"
            selected-label
            :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
            :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
          />
        </div>
      </label>
    </div>
    <twilio v-if="provider.value === 'twilio'" type="sms" />
    <bandwidth-sms v-else />
  </div>
</template>

<script>
import PageHeader from '../../SettingsSubPageHeader';
import BandwidthSms from './BandwidthSms.vue';
import Twilio from './Twilio';

export default {
  components: {
    PageHeader,
    Twilio,
    BandwidthSms,
  },
  data() {
    return {
      provider: {
        value: 'twilio',
        name: this.$t('INBOX_MGMT.ADD.SMS.PROVIDERS.TWILIO'),
      },

      providers: [
        {
          value: 'twilio',
          name: this.$t('INBOX_MGMT.ADD.SMS.PROVIDERS.TWILIO'),
        },
        {
          value: 'bandwidth',
          name: this.$t('INBOX_MGMT.ADD.SMS.PROVIDERS.BANDWIDTH'),
        },
      ],
    };
  },
};
</script>
