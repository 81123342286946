/* eslint no-console: 0 */
import * as types from '../../../mutation-types';
import metricsMessages from '../../../../api/reports/message/metricsMessages';

const state = {
  metrics: {
    incoming: {
      isFetching: false,
      data: [],
    },
    outgoing: {
      isFetching: false,
      data: [],
    },
  },
  messagesSummary: {
    isFetching: false,
    data: [],
  },
};

const getters = {
  getMerictsIncoming(_state) {
    return _state.metrics.incoming;
  },
  getMerictsOutgoing(_state) {
    return _state.metrics.outgoing;
  },
  getMessagesSummary(_state) {
    return _state.messagesSummary;
  },
};

export const actions = {
  incomingMessages: async function getMerictsIncoming({ commit }, prams) {
    commit(types.default.TOGGLE_MESSAGE_INCOMING_METRICS, { isFetching: true });
    try {
      const response = await metricsMessages.incomingMetric(prams);
      commit(types.default.SET_MESSAGE_INCOMING_METRICS, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.default.TOGGLE_MESSAGE_INCOMING_METRICS, {
        isFetching: false,
      });
    }
  },

  outgoingMessages: async function getMerictsOutgoing({ commit }, prams) {
    commit(types.default.TOGGLE_MESSAGE_OUT_GOING_METRICS, {
      isFetching: true,
    });
    try {
      const response = await metricsMessages.outgoingMetric(prams);
      commit(types.default.SET_MESSAGE_OUT_GOING_METRICS, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.default.TOGGLE_MESSAGE_OUT_GOING_METRICS, {
        isFetching: false,
      });
    }
  },

  getSummary: async function getMessagesSummary({ commit }, prams) {
    commit(types.default.TOGGLE_MESSAGE_SUMMARY, { isFetching: true });
    try {
      const response = await metricsMessages.messagesSummary(prams);
      commit(types.default.SET_MESSAGE_SUMMARY, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.default.TOGGLE_MESSAGE_SUMMARY, { isFetching: false });
    }
  },
};

const mutations = {
  [types.default.SET_MESSAGE_INCOMING_METRICS](_state, metrics) {
    _state.metrics.incoming.data = metrics;
  },
  [types.default.TOGGLE_MESSAGE_INCOMING_METRICS](_state, flag) {
    _state.metrics.incoming.isFetching = flag.isFetching;
  },

  [types.default.SET_MESSAGE_OUT_GOING_METRICS](_state, metrics) {
    _state.metrics.outgoing.data = metrics;
  },
  [types.default.TOGGLE_MESSAGE_OUT_GOING_METRICS](_state, flag) {
    _state.metrics.outgoing.isFetching = flag.isFetching;
  },

  [types.default.SET_MESSAGE_SUMMARY](_state, data) {
    _state.messagesSummary.data = data;
  },
  [types.default.TOGGLE_MESSAGE_SUMMARY](_state, flag) {
    _state.messagesSummary.isFetching = flag.isFetching;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
