<template>
  <banner
    v-if="shouldShowBanner"
    class="update-banner"
    color-scheme="warning"
    :banner-message="bannerMessage"
    href-link="https://api.whatsapp.com/send?phone=966920007031"
    href-link-text="(Contact Us)"
  />
</template>
<script>
import Banner from 'dashboard/components/ui/Banner.vue';
import adminMixin from 'dashboard/mixins/isAdmin';

export default {
  components: { Banner },
  mixins: [adminMixin],
  props: {
    spendingGraceMacs: {
      type: Boolean,
      default: false,
    },
    limits: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return { userDismissedBanner: false };
  },
  computed: {
    bannerMessage() {
      return this.$t('GENERAL_SETTINGS.SPENDING_GRACE_MACS', {
        mac: this.limits?.grace_macs,
      });
    },
    shouldShowBanner() {
      return this.spendingGraceMacs && this.isAdmin;
    },
  },
};
</script>
