<template>
  <div class="wizard-body">
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.GBM.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.GBM.DESC')"
    />
    <woot-loading-state
      v-if="loading"
      :message="$t('INBOX_MGMT.ADD.GBM.LOADING_MESSAGE')"
    />
    <form v-if="!loading" @submit.prevent="onSubmit">
      <template v-if="step == 0">
        <div>
          <div class="medium-8 columns" :class="{ error: $v.name.$error }">
            <label>
              {{ $t('INBOX_MGMT.ADD.GBM.INBOX_NAME.LABEL') }}
              <input v-model.trim="$v.name.$model" type="text" />
              <p v-if="$v.name.$error" class="error m-0">
                <small>{{ $t('INBOX_MGMT.ADD.GBM.INBOX_NAME.ERROR') }}</small>
              </p>
              <span v-if="!$v.name.required && $v.name.$error" class="message">
                {{ $t('INBOX_MGMT.ADD.GBM.INBOX_NAME.ERROR') }}
              </span>
              <span
                v-else-if="!$v.name.maxLength && $v.name.required"
                class="message"
              >
                {{ $t('INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.ERROR.MAX_LENGTH') }}
              </span>
            </label>
          </div>

          <div class="medium-8 columns" :class="{ error: $v.brand_id.$error }">
            <label>
              {{ $t('INBOX_MGMT.ADD.GBM.BRAND_ID.LABEL') }}
              <input v-model.trim="$v.brand_id.$model" type="text" />
              <span
                v-if="!$v.brand_id.required && $v.brand_id.$error"
                class="message"
              >
                {{ $t('INBOX_MGMT.ADD.GBM.INBOX_NAME.ERROR') }}
              </span>
            </label>
            <p v-if="$v.brand_id.$error" class="error m-0">
              <small>{{ $t('INBOX_MGMT.ADD.GBM.BRAND_ID.ERROR') }}</small>
            </p>
          </div>

          <div
            class="medium-8 columns"
            :class="{ error: $v.channel.service_account_key.$error }"
          >
            <label>
              {{ $t('INBOX_MGMT.ADD.GBM.SERVICE_ACCOUNT.LABEL') }}
              <textarea
                v-model.trim="$v.channel.service_account_key.$model"
                rows="5"
              />
              <span
                v-if="
                  $v.channel.service_account_key.$dirty &&
                  !$v.channel.service_account_key.required
                "
                class="message"
              >
                {{ $t('INBOX_MGMT.ADD.GBM.SERVICE_ACCOUNT.ERROR.REQUIRED') }}
              </span>
              <span
                v-if="
                  $v.channel.service_account_key.$dirty &&
                  !$v.channel.service_account_key.jsonValidator
                "
                class="message"
              >
                {{ $t('INBOX_MGMT.ADD.GBM.SERVICE_ACCOUNT.ERROR.VALID') }}
              </span>
            </label>
          </div>
        </div>
      </template>
      <template v-if="step == 1">
        <div>
          <div
            class="medium-12 columns"
            :class="{ error: $v.channel.location_identifier.$error }"
          >
            <label>
              {{ $t('INBOX_MGMT.ADD.GBM.LOCATION.LABEL') }}
              <select v-model.trim="$v.channel.location_identifier.$model">
                <option v-for="location in locations" :key="location">
                  {{ location }}
                </option>
              </select>
              <p v-if="$v.channel.location_identifier.$error" class="error m-0">
                <small>{{ $t('INBOX_MGMT.ADD.GBM.LOCATION.ERROR') }}</small>
              </p>
            </label>
          </div>
        </div>
      </template>
      <div class="medium-12 columns">
        <woot-submit-button
          :loading="loading"
          :disabled="loading"
          :button-text="$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.SUBMIT_BUTTON')"
        />
      </div>
    </form>
  </div>
</template>
<script>
import PageHeader from '../../SettingsSubPageHeader';
import CustomApiClient from '../../../settings/reports/api/CustomAPIClient';
import { required, maxLength } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import alertMixin from 'shared/mixins/alertMixin';

const jsonValidator = value => {
  try {
    JSON.parse(value);
  } catch (e) {
    return false;
  }
  return true;
};

export default {
  name: 'GBM',
  components: {
    PageHeader,
  },
  mixins: [validationMixin, alertMixin],
  data() {
    return {
      step: 0,
      name: '',
      brand_id: '',
      channel: {
        type: 'google_business',
        service_account_key: '',
        location_identifier: '',
      },
      locations: [],
      loading: false,
    };
  },
  validations: {
    name: {
      required,
      maxLength: maxLength(50),
    },
    brand_id: {
      required,
    },
    channel: {
      service_account_key: {
        required,
        jsonValidator,
      },
      location_identifier: {
        required,
      },
    },
  },
  methods: {
    onSubmit(e) {
      this.$v.$touch();
      if (this.step === 0) {
        if (
          this.$v.brand_id.$invalid ||
          this.$v.channel.service_account_key.$invalid ||
          this.$v.name.$invalid
        ) {
          return;
        }
        this.$v.$reset();
        this.loading = true;
        let data = {
          brand_id: this.brand_id,
          service_account_key: JSON.parse(this.channel.service_account_key),
        };
        new CustomApiClient('callbacks/google_business_locations', 'v1')
          .post(data)
          .then(res => {
            this.step = 1;
            this.locations = [...res.data];
          })
          .catch(e => {
            this.showAlert(
              this.$t('INBOX_MGMT.ADD.GBM.API.ERROR_MESSAGE'),
              'error'
            );
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        if (this.$v.channel.location_identifier.$invalid) {
          return;
        }
        this.loading = true;
        this.$store
          .dispatch('inboxes/createChannel', {
            name: this.name,
            channel: {
              type: 'google_business',
              location_identifier: this.channel.location_identifier,
              service_account_key: JSON.parse(this.channel.service_account_key),
            },
          })
          .then(res => {
            this.$router.replace({
              name: 'settings_inboxes_add_agents',
              params: {
                page: 'new',
                inbox_id: res.id,
              },
            });
          })
          .catch(e => {
            this.showAlert(
              this.$t('INBOX_MGMT.ADD.GBM.API.ERROR_MESSAGE'),
              'error'
            );
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
<style scoped>
.error * {
  margin: 0;
}
</style>
