import ApiClient from './ApiClient';

class LogsReports extends ApiClient {
  constructor() {
    super('reports', { accountScoped: true, apiVersion: 'v3' });
    this.contactId = null;
  }

  get assignmentLogsUrl() {
    return `${this.baseUrl()}/assignment_logs`;
  }
  get commentLogsUrl() {
    return `${this.baseUrl()}/comment_logs`;
  }

  getAssignmentLogs(params) {
    return axios.get(this.assignmentLogsUrl, { params });
  }
  getCommentLogs(params) {
    return axios.get(this.commentLogsUrl, { params });
  }
}

export default new LogsReports();
