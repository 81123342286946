import { required, minLength, maxLength } from 'vuelidate/lib/validators';

export const validLabelCharacters = (str = '') => !!str && !str.includes(' ');

export default {
  title: {
    required,
    minLength: minLength(2),
    maxLength: maxLength(30),
    validLabelCharacters,
  },
  description: {},
  color: {
    required,
  },
  showOnSidebar: {},
};
