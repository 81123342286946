var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"profile-settings-notifications"}},[_c('div',{staticClass:"setting-item"},[_c('div',{staticClass:"text"},[_c('h4',{staticClass:"setting-item__text__title"},[_vm._v("\n        "+_vm._s(_vm.$t('PROFILE_SETTINGS.FORM.AUDIO_NOTIFICATIONS_SECTION.TITLE'))+"\n      ")]),_vm._v(" "),_c('p',{staticClass:"setting-item__text__help-text"},[_vm._v("\n        "+_vm._s(_vm.$t('PROFILE_SETTINGS.FORM.AUDIO_NOTIFICATIONS_SECTION.NOTE'))+"\n      ")])]),_vm._v(" "),_c('div',{staticClass:"my-3"},[_c('div',{staticClass:"notification-items--wrapper"},[_c('span',{staticClass:"text-block-title notification-label"},[_vm._v("\n          "+_vm._s(_vm.$t(
              'PROFILE_SETTINGS.FORM.AUDIO_NOTIFICATIONS_SECTION.ALERT_TYPE.TITLE'
            ))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"row"},_vm._l((_vm.audioNotification),function(item){return _c('div',{key:item.key,staticClass:"col-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.enableAudioAlerts),expression:"enableAudioAlerts"}],staticClass:"notification--checkbox",attrs:{"id":item.key,"type":"radio"},domProps:{"value":item.value,"checked":_vm._q(_vm.enableAudioAlerts,item.value)},on:{"input":_vm.handleAudioInput,"change":function($event){_vm.enableAudioAlerts=item.value}}}),_vm._v(" "),_c('label',{attrs:{"for":item.key}},[_vm._v("\n              "+_vm._s(_vm.$t(
                  ("PROFILE_SETTINGS.FORM.AUDIO_NOTIFICATIONS_SECTION.ALERT_TYPE." + (item.translationKey))
                ))+"\n            ")])])}),0)]),_vm._v(" "),_c('div',{staticClass:"notification-items--wrapper d-flex align-items-center gap-3"},[_c('span',{staticClass:"text-block-title notification-label"},[_vm._v("\n          "+_vm._s(_vm.$t(
              'PROFILE_SETTINGS.FORM.AUDIO_NOTIFICATIONS_SECTION.DEFAULT_TONE.TITLE'
            ))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"multiselect-wrap--small w-25"},[_c('multiselect',{attrs:{"track-by":"value","label":"label","selected-label":"","options":_vm.notificationAlertTones,"searchable":false,"multiple":false,"allow-empty":false},on:{"select":_vm.handleAudioToneChange},model:{value:(_vm.notificationTone),callback:function ($$v) {_vm.notificationTone=$$v},expression:"notificationTone"}})],1),_vm._v(" "),_c('div')]),_vm._v(" "),_c('div',{staticClass:"notification-items--wrapper"},[_c('span',{staticClass:"text-block-title notification-label"},[_vm._v("\n          "+_vm._s(_vm.$t(
              'PROFILE_SETTINGS.FORM.AUDIO_NOTIFICATIONS_SECTION.CONDITIONS.TITLE'
            ))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.playAudioWhenTabIsInactive),expression:"playAudioWhenTabIsInactive"}],staticClass:"notification--checkbox",attrs:{"id":"audio_alert_when_tab_is_inactive","type":"checkbox","value":"tab_is_inactive"},domProps:{"checked":Array.isArray(_vm.playAudioWhenTabIsInactive)?_vm._i(_vm.playAudioWhenTabIsInactive,"tab_is_inactive")>-1:(_vm.playAudioWhenTabIsInactive)},on:{"input":_vm.handleAudioAlertConditions,"change":function($event){var $$a=_vm.playAudioWhenTabIsInactive,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v="tab_is_inactive",$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.playAudioWhenTabIsInactive=$$a.concat([$$v]))}else{$$i>-1&&(_vm.playAudioWhenTabIsInactive=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.playAudioWhenTabIsInactive=$$c}}}}),_vm._v(" "),_c('label',{attrs:{"for":"audio_alert_when_tab_is_inactive"}},[_vm._v("\n              "+_vm._s(_vm.$t(
                  'PROFILE_SETTINGS.FORM.AUDIO_NOTIFICATIONS_SECTION.CONDITIONS.CONDITION_ONE'
                ))+"\n            ")])]),_vm._v(" "),_c('div',{staticClass:"col-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.alertIfUnreadConversationExist),expression:"alertIfUnreadConversationExist"}],staticClass:"notification--checkbox",attrs:{"id":"audio_alert_until_all_conversations_are_read","type":"checkbox","value":"conversations_are_read"},domProps:{"checked":Array.isArray(_vm.alertIfUnreadConversationExist)?_vm._i(_vm.alertIfUnreadConversationExist,"conversations_are_read")>-1:(_vm.alertIfUnreadConversationExist)},on:{"input":_vm.handleAudioAlertConditions,"change":function($event){var $$a=_vm.alertIfUnreadConversationExist,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v="conversations_are_read",$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.alertIfUnreadConversationExist=$$a.concat([$$v]))}else{$$i>-1&&(_vm.alertIfUnreadConversationExist=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.alertIfUnreadConversationExist=$$c}}}}),_vm._v(" "),_c('label',{attrs:{"for":"audio_alert_until_all_conversations_are_read"}},[_vm._v("\n              "+_vm._s(_vm.$t(
                  'PROFILE_SETTINGS.FORM.AUDIO_NOTIFICATIONS_SECTION.CONDITIONS.CONDITION_TWO'
                ))+"\n            ")])])])])])]),_vm._v(" "),_c('div',{staticClass:"setting-item"},[_c('div',{staticClass:"text"},[_c('h4',{staticClass:"setting-item__text__title"},[_vm._v("\n        "+_vm._s(_vm.$t('PROFILE_SETTINGS.FORM.EMAIL_NOTIFICATIONS_SECTION.TITLE'))+"\n      ")]),_vm._v(" "),_c('p',{staticClass:"setting-item__text__help-text"},[_vm._v("\n        "+_vm._s(_vm.$t('PROFILE_SETTINGS.FORM.EMAIL_NOTIFICATIONS_SECTION.NOTE'))+"\n      ")])]),_vm._v(" "),_c('div',{staticClass:"mt-4"},[_c('div',{staticClass:"row g-4"},_vm._l((_vm.notification),function(item){return _c('div',{key:("email_" + (item.value)),staticClass:"col-6"},[_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedEmailFlags),expression:"selectedEmailFlags"}],staticClass:"notification--checkbox",attrs:{"type":"checkbox"},domProps:{"value":("email_" + (item.value)),"checked":Array.isArray(_vm.selectedEmailFlags)?_vm._i(_vm.selectedEmailFlags,("email_" + (item.value)))>-1:(_vm.selectedEmailFlags)},on:{"input":_vm.handleEmailInput,"change":function($event){var $$a=_vm.selectedEmailFlags,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v="email_" + (item.value),$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selectedEmailFlags=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selectedEmailFlags=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selectedEmailFlags=$$c}}}}),_vm._v("\n            "+_vm._s(_vm.$t(
                ("PROFILE_SETTINGS.FORM.EMAIL_NOTIFICATIONS_SECTION." + (item.translationKey))
              ))+"\n          ")])])}),0)])]),_vm._v(" "),(_vm.vapidPublicKey && _vm.hasPushAPISupport)?_c('div',{staticClass:"setting-item"},[_c('div',{staticClass:"text"},[_c('h4',{staticClass:"setting-item__text__title"},[_vm._v("\n        "+_vm._s(_vm.$t('PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.TITLE'))+"\n      ")]),_vm._v(" "),_c('p',{staticClass:"setting-item__text__help-text"},[_vm._v("\n        "+_vm._s(_vm.$t('PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.NOTE'))+"\n      ")])]),_vm._v(" "),_c('div',[(_vm.hasEnabledPushPermissions)?_c('p',[_vm._v("\n        "+_vm._s(_vm.$t(
            'PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.HAS_ENABLED_PUSH'
          ))+"\n      ")]):_c('div',{staticClass:"push-notification--button"},[_c('woot-submit-button',{staticClass:"button nice small",attrs:{"button-text":_vm.$t(
              'PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.REQUEST_PUSH'
            ),"type":"button"},on:{"click":_vm.onRequestPermissions}})],1),_vm._v(" "),_c('div',{staticClass:"mt-4"},[_c('div',{staticClass:"row g-4"},_vm._l((_vm.notification),function(item){return _c('div',{key:("push_" + (item.value)),staticClass:"col-6"},[_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedPushFlags),expression:"selectedPushFlags"}],staticClass:"notification--checkbox",attrs:{"type":"checkbox"},domProps:{"value":("push_" + (item.value)),"checked":Array.isArray(_vm.selectedPushFlags)?_vm._i(_vm.selectedPushFlags,("push_" + (item.value)))>-1:(_vm.selectedPushFlags)},on:{"input":_vm.handlePushInput,"change":function($event){var $$a=_vm.selectedPushFlags,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v="push_" + (item.value),$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selectedPushFlags=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selectedPushFlags=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selectedPushFlags=$$c}}}}),_vm._v("\n              "+_vm._s(_vm.$t(
                  ("PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION." + (item.translationKey))
                ))+"\n            ")])])}),0)])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }