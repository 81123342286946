import VueRouter from 'vue-router';

import { frontendURL } from '../helper/URLHelper';
import { clearBrowserSessionCookies } from '../store/utils/api';
import authRoute from './auth/auth.routes';
import dashboard from './dashboard/dashboard.routes';
import login from './login/login.routes';
import store from '../store';
import { validateLoggedInRoutes } from '../helper/routeHelpers';
import AnalyticsHelper from '../helper/AnalyticsHelper';

const routes = [...login.routes, ...dashboard.routes, ...authRoute.routes];

window.roleWiseRoutes = {
  agent: [],
  administrator: [],
};

// generateRoleWiseRoute - updates window object with agent/admin route
const generateRoleWiseRoute = route => {
  route.forEach(element => {
    if (element.children) {
      generateRoleWiseRoute(element.children);
    }
    if (element.roles) {
      element.roles.forEach(roleEl => {
        window.roleWiseRoutes[roleEl].push(element.name);
      });
    }
  });
};

// Create a object of routes
// accessible by each role.
// returns an object with roles as keys and routeArr as values
// generateRoleWiseRoute(routes);

export const router = new VueRouter({ mode: 'history', routes });

const unProtectedRoutes = [
  'login',
  'auth_signup',
  'auth_reset_password',
  'auth_reset_check_email',
];

const authIgnoreRoutes = [
  'auth_confirmation',
  'pushBack',
  'auth_password_edit',
  'oauth-callback',
];

const validateSSOLoginParams = to => {
  const isLoginRoute = to.name === 'login';
  const { email, sso_auth_token: ssoAuthToken } = to.query || {};
  const hasValidSSOParams = email && ssoAuthToken;
  return isLoginRoute && hasValidSSOParams;
};


export const validateAuthenticateRoutePermission = (to, next, { getters }) => {
  const { isLoggedIn, getCurrentUser: user } = getters;
  const isProtectedRoute = !unProtectedRoutes.includes(to.name);

  if (!isProtectedRoute && isLoggedIn && user.accounts.length > 0) {
    return next(frontendURL('app'));
  }

  if (!isProtectedRoute && !isLoggedIn) {
    return next();
  }

  if (!isLoggedIn) {
    window.location = '/app/login';
    return '/app/login';
  }

  const nextRoute = validateLoggedInRoutes(
    to,
    getters.getCurrentUser,
    window.roleWiseRoutes
  );

  if (!isProtectedRoute) {
    return next();
  }

  return nextRoute ? next(frontendURL(nextRoute)) : next();
};

export const initalizeRouter = () => {
  const userAuthentication = store.dispatch('setUser');

  router.beforeEach((to, from, next) => {
    AnalyticsHelper.page(to.name || '', {
      path: to.path,
      name: to.name,
    });

    if (validateSSOLoginParams(to)) {
      clearBrowserSessionCookies();
      next();
      return;
    }

    userAuthentication.then(() => {
      if (
        window.socialAppConfig.signupEnabled !== 'true' &&
        to.meta &&
        to.meta.requireSignupEnabled
      ) {
        return next(frontendURL('login'));
      }

      if (authIgnoreRoutes.includes(to.name)) {
        return next();
      }

      return validateAuthenticateRoutePermission(to, next, store);
    });
  });
};

export default router;
