<template>
  <woot-button
    size="small"
    variant="clear"
    color-scheme="secondary"
    class="toggle-sidebar"
    @click="onMenuItemClick"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      class="d-block"
    >
      <path
        d="M2 12H14"
        stroke="#808080"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2 8H14"
        stroke="#808080"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2 4H14"
        stroke="#808080"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </woot-button>
</template>

<script>
import { BUS_EVENTS } from 'shared/constants/busEvents';

export default {
  methods: {
    onMenuItemClick() {
      bus.$emit(BUS_EVENTS.TOGGLE_SIDEMENU);
    },
  },
};
</script>
<style scoped lang="scss">
.toggle-sidebar {
  padding: 8px !important;
  margin-left: var(--space-minus-small);
  background: var(--b-50) !important;
}
</style>
