<template>
  <div v-if="notifications" class="notifications__list">
    <custom-notification-widget
      v-for="notification in notifications"
      :key="notification.id"
      :notification="notification"
    />
  </div>
</template>

<script>
import CustomNotificationWidget from './CustomNotificationWidget';
import { mapGetters } from 'vuex';

export default {
  components: {
    CustomNotificationWidget,
  },
  computed: {
    ...mapGetters({
      notifications: 'notifications/getFloatingNotifications',
    }),

    floatingNotifications() {
      return this.notifications.filter(
        notification =>
          notification.notification_type === 'conversation_mention' ||
          'participating_conversation_new_message' ||
          'conversation_created' ||
          'conversation_assignment'
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.notifications__list {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column-reverse;
  z-index: 100000;
}

.app-rtl--wrapper {
  .notifications__list {
    right: unset;
    left: 20px;
  }
}
</style>
