import { FEATURE_FLAGS } from '../../../../featureFlags';
import { frontendURL } from '../../../../helper/URLHelper';

const settings = accountId => ({
  parentNav: 'settings',
  routes: [
    'agent_bots',
    'agent_list',
    'attributes_list',
    'automation_list',
    'auditlogs_list',
    'billing_settings_index',
    'canned_list',
    'block_list',
    'general_settings_index',
    'general_settings',
    'labels_list',
    'macros_edit',
    'macros_new',
    'macros_wrapper',
    'settings_applications_integration',
    'settings_integrations_salla',
    'settings_integrations_salla_events',
    'settings_integrations_salla_event',
    'settings_applications_webhook',
    'settings_applications',
    'settings_inbox_finish',
    'settings_inbox_list',
    'settings_inbox_new',
    'settings_inbox_show',
    'settings_inbox',
    'settings_inboxes_add_template',
    'settings_inbox_settings',
    'settings_inbox_collaborators',
    'settings_inbox_configuration',
    'settings_inbox_preChatForm',
    'settings_inbox_widgetBuilder',
    'settings_inbox_templates',
    'settings_inbox_botConfiguration',
    'settings_inbox_commerceSettings',
    'settings_inboxes_add_agents',
    'settings_inboxes_page_channel',
    'settings_integrations_dashboard_apps',
    'settings_integrations_integration',
    'settings_integrations_webhook',
    'settings_integrations',
    'settings_teams_add_agents',
    'settings_teams_edit_finish',
    'settings_teams_edit_members',
    'settings_teams_edit',
    'settings_teams_finish',
    'settings_teams_list',
    'settings_teams_new',
    'file_list',
    'notes_list',
    'roles_settings_index',
    'roles_settings_add',
    'roles_settings_edit',
    'settings_home',
    'subscription_settings_index',
    'settings_AI_prompts',
    'settings_AI_knowledge'
  ],
  menuItems: [
    {
      icon: 'person',
      key: 'settings',
      label: 'PROFILE_SETTINGS',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/settings`),
      toStateName: 'settings_home',
    },
    {
      icon: 'briefcase',
      label: 'ACCOUNT_SETTINGS',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/settings/general`),
      toStateName: 'general_settings_index',
      permission: 'show_account_settings',
    },
    {
      icon: 'block',
      label: 'BLOCK_LIST',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/settings/block_list`),
      toStateName: 'block_list',
      permission: 'block_contacts',
    },
    {
      icon: 'people',
      label: 'AGENTS',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/settings/agents/list`),
      toStateName: 'agent_list',
      featureFlag: FEATURE_FLAGS.AGENT_MANAGEMENT,
      permission: 'show_agents',
    },
    {
      icon: 'people-team',
      label: 'TEAMS',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/settings/teams/list`),
      toStateName: 'settings_teams_list',
      featureFlag: FEATURE_FLAGS.TEAM_MANAGEMENT,
      permission: 'show_teams',
    },
    {
      icon: 'mail-inbox-all',
      label: 'INBOXES',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/settings/inboxes/list`),
      toStateName: 'settings_inbox_list',
      featureFlag: FEATURE_FLAGS.INBOX_MANAGEMENT,
      permission: 'show_inboxes',
    },
    {
      icon: 'tag',
      label: 'LABELS',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/settings/labels/list`),
      toStateName: 'labels_list',
      featureFlag: FEATURE_FLAGS.LABELS,
      permission: 'show_labels',
    },
    {
      icon: 'code',
      label: 'CONTACT_ATTRIBUTES',
      hasSubMenu: false,
      toState: frontendURL(
        `accounts/${accountId}/settings/custom-attributes/list`
      ),
      toStateName: 'attributes_list',
      featureFlag: FEATURE_FLAGS.CUSTOM_ATTRIBUTES,
      permission: 'show_attrs',
    },
    // {
    //   icon: 'automation',
    //   label: 'AUTOMATION',
    //   beta: true,
    //   hasSubMenu: false,
    //   toState: frontendURL(`accounts/${accountId}/settings/automation/list`),
    //   toStateName: 'automation_list',
    //   featureFlag: FEATURE_FLAGS.AUTOMATIONS,
    //   permission: 'show_automations',
    // },
    {
      icon: 'bot',
      label: 'AGENT_BOTS',
      beta: true,
      hasSubMenu: false,
      globalConfigFlag: 'csmlEditorHost',
      toState: frontendURL(`accounts/${accountId}/settings/agent-bots`),
      toStateName: 'agent_bots',
      featureFlag: FEATURE_FLAGS.AGENT_BOTS,
    },
    // {
    //   icon: 'flash-settings',
    //   label: 'MACROS',
    //   hasSubMenu: false,
    //   toState: frontendURL(`accounts/${accountId}/settings/macros`),
    //   toStateName: 'macros_wrapper',
    //   beta: true,
    //   featureFlag: FEATURE_FLAGS.MACROS,
    // },
    {
      icon: 'chat-multiple',
      label: 'CANNED_RESPONSES',
      hasSubMenu: false,
      toState: frontendURL(
        `accounts/${accountId}/settings/canned-response/list`
      ),
      toStateName: 'canned_list',
      featureFlag: FEATURE_FLAGS.CANNED_RESPONSES,
      permission: 'show_canned_responses',
    },
    {
      icon: 'file',
      label: 'CANNED_FILES',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/settings/canned-files/list`),
      toStateName: 'file_list',
      permission: 'show_canned_files',
    },
    {
      icon: 'sticky-note',
      label: 'CLOSING_NOTES',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/settings/closing-notes/list`),
      toStateName: 'notes_list',
      permission: 'show_closing_notes',
    },
    {
      icon: 'flash-on',
      label: 'INTEGRATIONS',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/settings/integrations`),
      toStateName: 'settings_integrations',
      featureFlag: FEATURE_FLAGS.INTEGRATIONS,
      permission: 'show_integrations',
    },
    // {
    //   icon: 'star-emphasis',
    //   label: 'APPLICATIONS',
    //   hasSubMenu: false,
    //   toState: frontendURL(`accounts/${accountId}/settings/applications`),
    //   toStateName: 'settings_applications',
    //   featureFlag: FEATURE_FLAGS.INTEGRATIONS,
    //   permission: 'show_applications',
    // },
    {
      icon: 'credit-card-person',
      label: 'BILLING',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/settings/billing`),
      toStateName: 'billing_settings_index',
      showOnlyOnCloud: true,
    },
    {
      icon: 'key',
      label: 'AUDIT_LOGS',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/settings/audit-log/list`),
      toStateName: 'auditlogs_list',
      featureFlag: FEATURE_FLAGS.AUDIT_LOGS,
      beta: true,
    },
    {
      icon: 'people',
      label: 'ROLES',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/settings/roles`),
      toStateName: 'roles_settings_index',
      permission: 'show_roles',
    },
    // {
    //   icon: 'workflow',
    //   label: 'WORKFLOWS',
    //   hasSubMenu: false,
    //   toState: frontendURL(`accounts/${accountId}/settings/workflows`),
    //   toStateName: 'settings_workflows',
    //   permission: 'show_workflows',
    // },
    {
      icon: 'subscription',
      label: 'SUBSCRIPTION',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/settings/subscription`),
      toStateName: 'subscription_settings_index',
      // permission: 'show_roles',
    },
    {
      icon: 'AI',
      label: 'BEVA_AI',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/settings/beva-ai`),
      toStateName: 'settings_AI',
      // permission: 'show_roles',
    },
  ],
});

export default settings;
