import { frontendURL } from '../../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/workflows'),
      component: () => import('../Wrapper'),
      props: params => {
        const showBackButton = params.name !== 'settings_workflows';
        const backUrl =
          params.name === 'settings_workflows_builder'
            ? { name: 'settings_workflows' }
            : '';
        return {
          headerTitle: 'WORKFLOWS.HEADER',
          icon: 'workflow',
          showNewButton: true,
          showBackButton,
          backUrl,
        };
      },
      children: [
        {
          path: '',
          name: 'settings_workflows',
          component: () => import('./Index'),
          roles: ['administrator'],
          meta: {
            permission: 'show_workflows',
          },
        },
        {
          path: ':builder_id/:isTemplate',
          name: 'settings_workflows_builder',
          component: () => import('./flowBuilder/Index'),
          roles: ['administrator'],
          props: route => ({
            builderId: route.params.builder_id,
          }),
          meta: {
            permission: 'show_workflows',
          },
        },
        {
          path: frontendURL('accounts/:accountId/use-template'),
          name: 'settings_workflows_template',
          component: () => import('./WorkflowTemplate/Index.vue'),
          roles: ['administrator'],
          meta: {
            permission: 'show_workflows',
          },
        },
        {
          path: frontendURL('accounts/:accountId/use-template/:id'),
          name: 'settings_workflows_single_template',
          component: () => import('./WorkflowTemplate/SingleTemplate.vue'),
          roles: ['administrator'],
          props: route => ({
            isTemplate: route.params.isTemplate,
          }),
          meta: {
            permission: 'show_workflows',
          },
        },
      ],
    },
  ],
};
