import { frontendURL } from '../../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/canned-files'),
      component: () => import('../Wrapper'),
      props: {
        headerTitle: 'SIDEBAR.CANNED_FILES',
        icon: 'file',
        showNewButton: false,
      },
      children: [
        {
          path: '',
          name: 'file_wrapper',
          redirect: 'list',
        },
        {
          path: 'list',
          name: 'file_list',
          roles: ['administrator'],
          component: () => import('./Index'),
          meta: {
            permission: 'show_canned_files',
          },
        },
      ],
    },
  ],
};
