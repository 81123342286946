/* eslint no-console: 0 */
import * as types from '../mutation-types';
import ConversationLogs from '../../api/conversationLogsAPI';

const state = {
  fetchingStatus: false,
  conversationLogsData: {
    data: [],
    meta: {},
  },
};

const getters = {
  getConversationLogsData(_state) {
    return _state.conversationLogsData;
  },
  getConversationLogsUIFlags(_state) {
    return _state.fetchingStatus;
  },
};

export const actions = {
  async fetchConversationLogsData({ commit }, reportObj) {
    commit(types.default.TOGGLE_CONVERSATION_LOG, true);
    try {
      const { data } = await ConversationLogs.getConversationLogs(reportObj);
      commit(types.default.SET_CONVERSATION_LOG, data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.default.TOGGLE_CONVERSATION_LOG, false);
    }
  },
};

const mutations = {
  [types.default.SET_CONVERSATION_LOG](_state, conversationLogs) {
    _state.conversationLogsData.data = conversationLogs.payload;
    _state.conversationLogsData.meta = conversationLogs.meta;
  },
  [types.default.TOGGLE_CONVERSATION_LOG](_state, flag) {
    _state.fetchingStatus = flag;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
