import { frontendURL } from '../../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/beva-ai'),
      roles: ['administrator'],
      component: () => import('../Wrapper'),
      props: {
        headerTitle: 'SIDEBAR.BEVA_AI',
        icon: 'AI',
        showNewButton: false,
      },
      children: [
        {
          path: '',
          component: () => import('./index.vue'),
          children:[
            {
              path: '',
              redirect: 'prompts',
            },
            {
              path: 'prompts',
              name: 'settings_AI_prompts',
              component: () => import('./prompts.vue'),
            },
            {
              path: 'knowledge-sources',
              name: 'settings_AI_knowledge',
              component: () => import('./knowledge'),
            },
          ]
        },
      ],
    },
  ],
};
