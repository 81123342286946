/* eslint no-console: 0 */
import * as types from '../mutation-types';
import reportsConversation from '../../api/reportsConversation';

const state = {
  fetchingStatus: false,
  reportsLogs: [],
  conversationLogsSummary: {
    isFetching: false,
    data: [],
  },
  openedConversationSummary: {
    isFetching: false,
    data: [],
  },
  closedConversationsBySource: {
    isFetching: false,
    data: [],
  },
  closedConversationsByCategory: {
    isFetching: false,
    data: [],
  },
  conversationMetricSummary: {
    isFetching: false,
    data: [],
  },
  openedConversationLog: {
    isFetching: false,
    data: [],
  },
  openedConversationLogBySource: {
    isFetching: false,
    data: [],
  },
  conversationMetricPerformance: {
    isFetching: false,
    data: [],
  },
  conversationLogsPerformance: {
    isFetching: false,
    data: [],
  },
};

const getters = {
  getConversationLogsSummary(_state) {
    return _state.conversationLogsSummary;
  },
  getOpenedConversationSummary(_state) {
    return _state.openedConversationSummary;
  },
  getCLosedConversationSummary(_state) {
    return _state.closedConversationsBySource;
  },
  getCLosedConversationSummaryByCategory(_state) {
    return _state.closedConversationsByCategory;
  },
  getConversationMetricSummary(_state) {
    return _state.conversationMetricSummary;
  },
  getOpenedConversationLog(_state) {
    return _state.openedConversationLog;
  },
  getOpenedConversationLogBySource(_state) {
    return _state.openedConversationLogBySource;
  },
  getConversationLogsPerformance(_state) {
    return _state.conversationLogsPerformance;
  },
  getConversationMetricPerformance(_state) {
    return _state.conversationMetricPerformance;
  },
};

export const actions = {
  fetchConversationLogsSummary({ commit }, reportObj) {
    commit(types.default.TOGGLE_REPORTS_CONVERSATION_LOG, true);
    reportsConversation.getConversationLogsSummary(reportObj).then(data => {
      commit(types.default.SET_REPORTS_CONVERSATION_LOG, data);
      commit(types.default.TOGGLE_REPORTS_CONVERSATION_LOG, false);
    });
  },
  fetchOpenedConversationSummary({ commit }, reportObj) {
    commit(types.default.TOGGLE_REPORTS_CONVERSATION_LOG, true);
    reportsConversation.getOpenedConversationSummary(reportObj).then(data => {
      commit(types.default.SET_OPENED_CONVERSATION_LOG, data);
      commit(types.default.TOGGLE_OPENED_CONVERSATION_LOG, false);
    });
  },
  fetchclosedConversationsBySource({ commit }, reportObj) {
    commit(types.default.TOGGLE_CLOSED_CONVERSATION_LOG, true);
    reportsConversation.getChartData(reportObj).then(data => {
      commit(types.default.SET_CLOSED_CONVERSATION_LOG, data);
      commit(types.default.TOGGLE_CLOSED_CONVERSATION_LOG, false);
    });
  },
  fetchclosedConversationsByCategory({ commit }, reportObj) {
    commit(types.default.TOGGLE_CLOSED_CONVERSATION_LOG_BY_CATEGORY, true);
    reportsConversation.getChartData(reportObj).then(data => {
      commit(types.default.SET_CLOSED_CONVERSATION_LOG_BY_CATEGORY, data);
      commit(types.default.TOGGLE_CLOSED_CONVERSATION_LOG_BY_CATEGORY, false);
    });
  },
  fetchConversationMetricSummary({ commit }, reportObj) {
    commit(types.default.TOGGLE_REPORTS_METRIC_SUMMARY, true);
    reportsConversation.getConversationMetricSummary(reportObj).then(data => {
      commit(types.default.SET_REPORTS_METRIC_SUMMARY, data);
      commit(types.default.TOGGLE_REPORTS_METRIC_SUMMARY, false);
    });
  },

  fetchOpenedConversationLogs({ commit }, reportObj) {
    commit(types.default.TOGGLE_REPORTS_METRIC_SUMMARY, true);
    reportsConversation
      .getConversationMetricSummary(reportObj, 'contact_type')
      .then(data => {
        commit(types.default.SET_OPENED_CONVERSATION_SUMMARY, data);
        commit(types.default.TOGGLE_OPENED_CONVERSATION_SUMMARY, false);
      });
  },

  fetchOpenedConversationLogsBySource({ commit }, reportObj) {
    commit(types.default.TOGGLE_REPORTS_METRIC_SUMMARY, true);
    reportsConversation
      .getConversationMetricSummary(reportObj, 'source')
      .then(data => {
        commit(types.default.SET_OPENED_CONVERSATION_SUMMARY_BY_SOURCE, data);
        commit(types.default.TOGGLE_OPENED_CONVERSATION_SUMMARY, false);
      });
  },

  fetchConversationLogsPerformance({ commit }, reportObj) {
    commit(types.default.TOGGLE_REPORTS_CONVERSATION_PERFORMANCE, true);
    reportsConversation.getConversationLogsPerformance(reportObj).then(data => {
      commit(types.default.SET_REPORTS_CONVERSATION_PERFORMANCE, data);
      commit(types.default.TOGGLE_REPORTS_CONVERSATION_PERFORMANCE, false);
    });
  },

  fetchConversationMetricPerformance({ commit }, reportObj) {
    commit(types.default.TOGGLE_REPORTS_METRIC_PERFORMANCE, true);
    reportsConversation
      .getConversationMetricPerformance(reportObj)
      .then(data => {
        commit(types.default.SET_REPORTS_METRIC_PERFORMANCE, data);
        commit(types.default.TOGGLE_REPORTS_METRIC_PERFORMANCE, false);
      });
  },
};

const mutations = {
  // Logs
  [types.default.SET_REPORTS_CONVERSATION_LOG](_state, conversationLogs) {
    _state.conversationLogsSummary.data = conversationLogs.data;
  },
  [types.default.TOGGLE_REPORTS_CONVERSATION_LOG](_state, flag) {
    _state.conversationLogsSummary.isFetching = flag;
  },

  [types.default.SET_CLOSED_CONVERSATION_LOG](_state, conversationLogs) {
    _state.closedConversationsBySource.data = conversationLogs.data;
  },
  [types.default.TOGGLE_CLOSED_CONVERSATION_LOG](_state, flag) {
    _state.closedConversationsBySource.isFetching = flag;
  },
  [types.default.SET_CLOSED_CONVERSATION_LOG_BY_CATEGORY](
    _state,
    conversationLogs
  ) {
    _state.closedConversationsByCategory.data = conversationLogs.data;
  },
  [types.default.TOGGLE_CLOSED_CONVERSATION_LOG_BY_CATEGORY](_state, flag) {
    _state.closedConversationsByCategory.isFetching = flag;
  },
  [types.default.SET_OPENED_CONVERSATION_LOG](_state, conversationLogs) {
    _state.openedConversationSummary.data = conversationLogs.data;
  },
  [types.default.TOGGLE_OPENED_CONVERSATION_LOG](_state, flag) {
    _state.openedConversationSummary.isFetching = flag;
  },

  // Summary
  [types.default.SET_REPORTS_METRIC_SUMMARY](_state, conversationLogs) {
    _state.conversationMetricSummary.data = conversationLogs.data;
  },
  [types.default.TOGGLE_REPORTS_METRIC_SUMMARY](_state, flag) {
    _state.conversationMetricSummary.isFetching = flag;
  },
  [types.default.SET_OPENED_CONVERSATION_SUMMARY](_state, conversationLogs) {
    _state.openedConversationLog.data = conversationLogs.data;
  },
  [types.default.TOGGLE_OPENED_CONVERSATION_SUMMARY](_state, flag) {
    _state.openedConversationLog.isFetching = flag;
  },
  [types.default.SET_OPENED_CONVERSATION_SUMMARY_BY_SOURCE](
    _state,
    conversationLogs
  ) {
    _state.openedConversationLogBySource.data = conversationLogs.data;
  },
  [types.default.TOGGLE_OPENED_CONVERSATION_SUMMARY_BY_SOURCE](_state, flag) {
    _state.openedConversationLogBySource.isFetching = flag;
  },

  // performance summary
  [types.default.SET_REPORTS_CONVERSATION_PERFORMANCE](
    _state,
    conversationLogs
  ) {
    _state.conversationLogsPerformance.data = conversationLogs.data;
  },
  [types.default.TOGGLE_REPORTS_CONVERSATION_PERFORMANCE](_state, flag) {
    _state.conversationLogsPerformance.isFetching = flag;
  },

  // Performance
  [types.default.SET_REPORTS_METRIC_PERFORMANCE](_state, conversationLogs) {
    _state.conversationMetricPerformance.data = conversationLogs.data;
  },
  [types.default.TOGGLE_REPORTS_METRIC_PERFORMANCE](_state, flag) {
    _state.conversationMetricPerformance.isFetching = flag;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
