import types from '../mutation-types';
export const state = {
  params: {},
};

export const getters = {
  getParams(_state) {
    return _state.params;
  },
};
export const actions = {
  setParams({ commit }, params) {
    commit(types.SET_FILTER_PARAMS, params);
  },
  emptyParams({ commit }) {
    commit(types.SET_FILTER_PARAMS, {});
  },
};

export const mutations = {
  [types.SET_FILTER_PARAMS](_state, data) {
    _state.params = { ...data };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
