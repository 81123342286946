<template>
  <div class="text--container">
    <highlightjs
      class="w-100"
      v-if="value"
      :code="masked ? '•'.repeat(10) : value"
    >
    </highlightjs>
    <div class="buttons">
      <woot-button
        variant="clear"
        size="small"
        class="button--visibility"
        color-scheme="secondary"
        :icon="masked ? 'eye-show' : 'eye-hide'"
        @click.prevent="toggleMasked"
      />
      <woot-button class="button-copy" @click="onCopy">
        <div class="d-flex gap-3">
          <i class="fa-regular fa-copy"></i>
          {{ $t('COMPONENTS.CODE.BUTTON_TEXT') }}
        </div>
      </woot-button>
    </div>
  </div>
</template>

<script>
import 'highlight.js/styles/default.css';
import { copyTextToClipboard } from 'shared/helpers/clipboard';

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      masked: true,
    };
  },
  methods: {
    async onCopy(e) {
      e.preventDefault();
      await copyTextToClipboard(this.value);
      bus.$emit('newToastMessage', this.$t('COMPONENTS.CODE.COPY_SUCCESSFUL'));
    },
    toggleMasked() {
      this.masked = !this.masked;
    },
  },
};
</script>

<style lang="scss" scoped>
.text--container {
  display: flex;
  justify-content: space-between;
  background-color: var(--w-25);
  border-radius: 16px;
  padding: 0px 12px;
  .buttons {
    display: flex;
    align-items: center;
    gap: 16px;
    .button-copy {
      background-color: var(--w-50);
      border: 1px solid var(--color-woot);
      color: var(--color-woot);
      padding: 12px 24px;
      border-radius: 8px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      &:hover {
        background-color: var(--color-woot);
        border: 1px solid var(--color-woot);
        color: white;
      }
    }
  }
  &::v-deep {
    code.hljs {
      background-color: none;
    }
  }
}
</style>
