import { frontendURL } from '../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/overview'),
      component: () => import('../settings/Wrapper'),
      props: {
        headerTitle: 'SIDEBAR.DASHBOARDOVERVIEW',
        icon: 'speedometer',
        keepAlive: false,
        showSidemenuIcon: false,
      },
      children: [
        {
          path: '',
          name: 'overview_default',
          component: () => import('./Index'),
          roles: ['administrator'],
          meta: {
            permission: 'show_dashboard',
          },
        },
      ],
    },
  ],
};
