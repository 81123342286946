import ApiClient from './ApiClient';

class Salla extends ApiClient {
  constructor() {
    super('integrations/salla', { accountScoped: true });
  }

  getAllEvents(inbox_id) {
    return axios.get(`${this.url}_events`, {
      params: {
        inbox_id
      },
    });
  }
  getEvent(params) {
    return axios.get(`${this.url}_events/${params.eventId}`, {
      params: {
        inbox_id: params.inboxId
      },
    });
  }
  getSallaInboxes() {
    return axios.get(`${this.url}_events/inboxes`);
  }
  getSallaConfig() {
    return axios.post(`${this.url}_connections/connect`);
  }
  checkSallaStatue() {
    return axios.get(`${this.url}_connections/check`);
  }
  update(params) {
    return axios.patch(`${this.url}_events/${params.id}`, {
      ...params.eventParams
    });
  }
}

export default new Salla();
