import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../mutation-types';
import RoleAPI from '../../api/roles';

const state = {
  records: [],
  uiFlags: {
    fetchingList: false,
    fetchingItem: false,
    creatingItem: false,
    updatingItem: false,
    deletingItem: false,
  },
};

const getters = {
  getRoles(_state) {
    return _state.records;
  },
  getUIFlags(_state) {
    return _state.uiFlags;
  },
};

const actions = {
  getRole: async function getRole({ commit }) {
    commit(types.default.SET_ROLE_UI_FLAG, { fetchingList: true });
    try {
      const response = await RoleAPI.get();
      commit(types.default.SET_ROLE, response.data);
      commit(types.default.SET_ROLE_UI_FLAG, { fetchingList: false });
    } catch (error) {
      commit(types.default.SET_ROLE_UI_FLAG, { fetchingList: false });
    }
  },

  createRole: async function createRole({ commit }, cannedObj) {
    commit(types.default.SET_ROLE_UI_FLAG, { creatingItem: true });
    try {
      const response = await RoleAPI.create(cannedObj);
      commit(types.default.ADD_ROLE, response.data);
      commit(types.default.SET_ROLE_UI_FLAG, { creatingItem: false });
    } catch (error) {
      commit(types.default.SET_ROLE_UI_FLAG, { creatingItem: false });
      throw error;
    }
  },

  updateRole: async function updateRole({ commit }, { id, ...updateObj }) {
    commit(types.default.SET_ROLE_UI_FLAG, { updatingItem: true });
    try {
      const response = await RoleAPI.update(id, updateObj);
      commit(types.default.EDIT_ROLE, response.data);
      commit(types.default.SET_ROLE_UI_FLAG, { updatingItem: false });
    } catch (error) {
      commit(types.default.SET_ROLE_UI_FLAG, { updatingItem: false });
      throw error;
    }
  },

  deleteRole: async function deleteRole({ commit }, id) {
    commit(types.default.SET_ROLE_UI_FLAG, { deletingItem: true });
    try {
      await RoleAPI.delete(id);
      commit(types.default.DELETE_ROLE, id);
      commit(types.default.SET_ROLE_UI_FLAG, { deletingItem: true });
    } catch (error) {
      commit(types.default.SET_ROLE_UI_FLAG, { deletingItem: true });
      throw error;
    }
  },
};

const mutations = {
  [types.default.SET_ROLE_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.default.SET_ROLE]: MutationHelpers.set,
  [types.default.ADD_ROLE]: MutationHelpers.create,
  [types.default.EDIT_ROLE]: MutationHelpers.update,
  [types.default.DELETE_ROLE]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
