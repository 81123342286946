<template>
  <div class="columns profile--settings">
    <div class="content-wrap">
      <woot-tabs
        class="setting-nav-tabs"
        :index="selectedTabIndex"
        :border="false"
        @change="onTabChange"
      >
        <woot-tabs-item
          v-for="tab in tabs"
          :key="tab.key"
          :name="tab.name"
          :show-badge="false"
        />
      </woot-tabs>
      <transition-group name="flash">
        <div v-if="selectedTabIndex == 0" key="personal_settings">
          <form @submit.prevent="updateUser('profile')">
            <div class="setting-item">
              <div class="text">
                <h4 class="setting-item__text__title">
                  {{ $t('PROFILE_SETTINGS.FORM.PROFILE_SECTION.TITLE') }}
                </h4>
              </div>
              <div class="avatar">
                <label>
                  {{ $t('PROFILE_SETTINGS.FORM.PROFILE_IMAGE.LABEL') }}
                </label>
                <woot-avatar-uploader
                  :src="avatarUrl"
                  :delete-avatar="true"
                  :username-avatar="name"
                  @onAvatarDelete="deleteAvatar"
                  @change="handleImageUpload"
                />
              </div>
              <div class="row gy-2">
                <div class="col-6">
                  <label :class="{ error: $v.name.$error }">
                    {{ $t('PROFILE_SETTINGS.FORM.NAME.LABEL') }}
                    <input
                      v-model="name"
                      type="text"
                      :placeholder="
                        $t('PROFILE_SETTINGS.FORM.NAME.PLACEHOLDER')
                      "
                      @input="$v.name.$touch"
                    />
                    <span v-if="$v.name.$error" class="message">
                      {{ $t('PROFILE_SETTINGS.FORM.NAME.ERROR') }}
                    </span>
                  </label>
                </div>
                <div class="col-6">
                  <label :class="{ error: $v.displayName.$error }">
                    {{ $t('PROFILE_SETTINGS.FORM.DISPLAY_NAME.LABEL') }}
                    <input
                      v-model="displayName"
                      type="text"
                      :placeholder="
                        $t('PROFILE_SETTINGS.FORM.DISPLAY_NAME.PLACEHOLDER')
                      "
                      @input="$v.displayName.$touch"
                    />
                  </label>
                </div>
                <div class="col-12">
                  <label class="relative" :class="{ error: $v.locale.$error }">
                    {{ $t('GENERAL_SETTINGS.FORM.LANGUAGE.LABEL') }}
                    <div class="multiselect-wrap--small">
                      <multiselect
                        v-model="locale"
                        track-by="iso_639_1_code"
                        label="name"
                        selected-label
                        :placeholder="
                          $t('GENERAL_SETTINGS.FORM.LANGUAGE.PLACEHOLDER')
                        "
                        :options="languagesSortedByCode"
                        :allow-empty="false"
                        :searchable="false"
                        :multiple="false"
                      />
                    </div>
                    <span v-if="$v.locale.$error" class="message">
                      {{ $t('GENERAL_SETTINGS.FORM.LANGUAGE.ERROR') }}
                    </span>
                  </label>
                </div>
                <div class="col-12">
                  <label
                    v-if="!globalConfig.disableUserProfileUpdate"
                    :class="{ error: $v.email.$error }"
                  >
                    {{ $t('PROFILE_SETTINGS.FORM.EMAIL.LABEL') }}
                    <input
                      v-model.trim="email"
                      type="email"
                      :placeholder="
                        $t('PROFILE_SETTINGS.FORM.EMAIL.PLACEHOLDER')
                      "
                      @input="$v.email.$touch"
                    />
                    <span v-if="$v.email.$error" class="message">
                      {{ $t('PROFILE_SETTINGS.FORM.EMAIL.ERROR') }}
                    </span>
                  </label>
                </div>
              </div>
              <div class="text-end">
                <woot-button type="submit" :is-loading="isProfileUpdating">
                  {{ $t('PROFILE_SETTINGS.BTN_TEXT') }}
                </woot-button>
              </div>
            </div>
          </form>
          <message-signature />
          <div class="setting-item">
            <div class="text">
              <h4 class="setting-item__text__title">
                {{ $t('PROFILE_SETTINGS.FORM.SEND_MESSAGE.TITLE') }}
              </h4>
              <p class="setting-item__text__help-text">
                {{ $t('PROFILE_SETTINGS.FORM.SEND_MESSAGE.NOTE') }}
              </p>
            </div>
            <div class="card-preview">
              <button
                v-for="keyOption in keyOptions"
                :key="keyOption.key"
                class="preview-button"
                @click="toggleEditorMessageKey(keyOption.key)"
              >
                <preview-card
                  :heading="keyOption.heading"
                  :content="keyOption.content"
                  :active="isEditorHotKeyEnabled(uiSettings, keyOption.key)"
                />
              </button>
            </div>
          </div>
          <change-password v-if="!globalConfig.disableUserProfileUpdate" />
          <div class="setting-item">
            <div class="text">
              <h4 class="setting-item__text__title">
                {{ $t('PROFILE_SETTINGS.FORM.ACCESS_TOKEN.TITLE') }}
              </h4>
              <p>
                {{
                  useInstallationName(
                    $t('PROFILE_SETTINGS.FORM.ACCESS_TOKEN.NOTE'),
                    globalConfig.installationName
                  )
                }}
              </p>
            </div>
            <div class="my-3">
              <masked-text :value="currentUser.access_token" />
            </div>
          </div>
        </div>
        <notification-settings v-else key="notification_setting" />
      </transition-group>
    </div>
  </div>
</template>

<script>
import { required, minLength, email } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import { clearCookiesOnLogout } from '../../../../store/utils/api';
import NotificationSettings from './NotificationSettings';
import alertMixin from 'shared/mixins/alertMixin';
import ChangePassword from './ChangePassword';
import MessageSignature from './MessageSignature';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import uiSettingsMixin, {
  isEditorHotKeyEnabled,
} from 'dashboard/mixins/uiSettings';
import MaskedText from '../components/MaskedText.vue';
import PreviewCard from 'dashboard/components/ui/PreviewCard.vue';
import configMixin from 'shared/mixins/configMixin';
import { getLanguageDirection } from 'dashboard/components/widgets/conversation/advancedFilterItems/languages';

export default {
  components: {
    NotificationSettings,
    ChangePassword,
    MessageSignature,
    PreviewCard,
    MaskedText,
  },
  mixins: [alertMixin, globalConfigMixin, configMixin, uiSettingsMixin],
  data() {
    return {
      avatarFile: '',
      avatarUrl: '',
      name: '',
      displayName: '',
      email: '',
      isProfileUpdating: false,
      errorMessage: '',
      locale: { name: 'English (en)', iso_639_1_code: 'en' },
      keyOptions: [
        {
          key: 'enter',
          src: '/assets/images/dashboard/editor/enter-editor.png',
          heading: this.$t(
            'PROFILE_SETTINGS.FORM.SEND_MESSAGE.CARD.ENTER_KEY.HEADING'
          ),
          content: this.$t(
            'PROFILE_SETTINGS.FORM.SEND_MESSAGE.CARD.ENTER_KEY.CONTENT'
          ),
        },
        {
          key: 'cmd_enter',
          src: '/assets/images/dashboard/editor/cmd-editor.png',
          heading: this.$t(
            'PROFILE_SETTINGS.FORM.SEND_MESSAGE.CARD.CMD_ENTER_KEY.HEADING'
          ),
          content: this.$t(
            'PROFILE_SETTINGS.FORM.SEND_MESSAGE.CARD.CMD_ENTER_KEY.CONTENT'
          ),
        },
      ],
      selectedTabIndex: 0,
    };
  },
  validations: {
    name: {
      required,
      minLength: minLength(1),
    },
    displayName: {},
    email: {
      required,
      email,
    },
    locale: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      currentUserId: 'getCurrentUserID',
      globalConfig: 'globalConfig/get',
      getAccount: 'accounts/getAccount',
      accountId: 'getCurrentAccountId',
    }),
    languagesSortedByCode() {
      const enabledLanguages = [...this.enabledLanguages];
      return enabledLanguages.sort((l1, l2) =>
        l1.iso_639_1_code.localeCompare(l2.iso_639_1_code)
      );
    },
    tabs() {
      return [
        {
          key: 'personal_settings',
          name: this.$t('PROFILE_SETTINGS.TABS.PERSONAL'),
        },
        {
          key: 'notification_setting',
          name: this.$t('PROFILE_SETTINGS.TABS.NOTIFICATIONS'),
        },
      ];
    },
  },
  watch: {
    currentUserId(newCurrentUserId, prevCurrentUserId) {
      if (prevCurrentUserId !== newCurrentUserId) {
        this.initializeUser();
      }
    },
  },
  mounted() {
    if (this.currentUserId) {
      this.initializeUser();
    }
  },
  methods: {
    async initializeUser() {
      let lang = [
        { name: 'English (en)', iso_639_1_code: 'en' },
        { name: 'العربية (ar)', iso_639_1_code: 'ar' },
      ];
      this.name = this.currentUser.name;
      this.email = this.currentUser.email;
      this.avatarUrl = this.currentUser.avatar_url;
      this.displayName = this.currentUser.display_name;
      await this.$store.dispatch('accounts/get');

      const { locale } = this.getAccount(this.accountId);
      this.$root.$i18n.locale = locale;
      this.locale = lang.find(lang => lang.iso_639_1_code == locale);
    },
    isEditorHotKeyEnabled,
    updateDirectionView(locale) {
      const isRTLSupported = getLanguageDirection(locale);
      this.updateUISettings({
        rtl_view: isRTLSupported,
      });
    },
    async updateUser() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.showAlert(this.$t('PROFILE_SETTINGS.FORM.ERROR'), 'error');
        return;
      }

      let alertType;
      this.isProfileUpdating = true;
      const hasEmailChanged = this.currentUser.email !== this.email;
      try {
        await this.$store.dispatch('updateProfile', {
          locale: this.locale.iso_639_1_code,
          name: this.name,
          email: this.email,
          avatar: this.avatarFile,
          displayName: this.displayName,
        });
        this.$root.$i18n.locale = this.locale.iso_639_1_code;
        this.updateDirectionView(this.locale.iso_639_1_code);
        this.isProfileUpdating = false;
        if (hasEmailChanged) {
          clearCookiesOnLogout();
          this.errorMessage = this.$t('PROFILE_SETTINGS.AFTER_EMAIL_CHANGED');
        }
        this.errorMessage = this.$t('PROFILE_SETTINGS.UPDATE_SUCCESS');
        alertType = 'success';
      } catch (error) {
        this.errorMessage = this.$t('RESET_PASSWORD.API.ERROR_MESSAGE');
        if (error?.response?.data?.error) {
          this.errorMessage = error.response.data.error;
          alertType = 'error';
        }
      } finally {
        this.isProfileUpdating = false;
        this.showAlert(this.errorMessage, alertType);
      }
    },
    handleImageUpload({ file, url }) {
      this.avatarFile = file;
      this.avatarUrl = url;
    },
    async deleteAvatar() {
      try {
        await this.$store.dispatch('deleteAvatar');
        this.avatarUrl = '';
        this.avatarFile = '';
        this.showAlert(this.$t('PROFILE_SETTINGS.AVATAR_DELETE_SUCCESS'));
      } catch (error) {
        this.showAlert(
          this.$t('PROFILE_SETTINGS.AVATAR_DELETE_FAILED'),
          'error'
        );
      }
    },
    showDeleteButton() {
      return this.avatarUrl && !this.avatarUrl.includes('www.gravatar.com');
    },
    toggleEditorMessageKey(key) {
      this.updateUISettings({ editor_message_key: key });
      this.showAlert(
        this.$t('PROFILE_SETTINGS.FORM.SEND_MESSAGE.UPDATE_SUCCESS')
      );
    },
    onTabChange(selectedTabIndex) {
      this.selectedTabIndex = selectedTabIndex;
    },
  },
};
</script>

<style lang="scss" scoped>
.profile--settings {
  overflow: auto;
  padding: 24px;
}

.content-wrap {
  overflow: hidden;
  background: #ffffff;
  border: 1px solid var(--b-200);
  border-radius: 16px;
  padding: 24px;
}
.setting-item {
  border: 1px solid var(--b-100);
  padding: 24px 16px;
  border-radius: 16px;
  margin-bottom: 48px;
  &__text {
    &__title {
      color: var(--color-woot);
      opacity: 0.5;
      font-size: 18px;
      font-weight: 600;
    }
    &__help-text {
      color: var(--b-900);
      font-size: 14px;
      font-weight: 400;
    }
  }
  .avatar {
    border: 1px solid var(--b-100);
    margin: 24px 0;
    padding: 16px;
    border-radius: 8px;
  }
  .multiselect {
    &::v-deep {
      .multiselect__tags {
        border: 1px solid var(--s-200);
        border-radius: 0.5rem;
      }
    }
  }
  .card-preview {
    display: flex;
    justify-content: space-around;
    margin: 16px;
    .preview-button {
      cursor: pointer;
    }
  }
}

// flash animation for tabs
.flash-move,
.flash-enter-active,
.flash-leave-active {
  transition: all 0.3s ease;
}

.flash-enter-from,
.flash-leave-to {
  opacity: 0;
}
</style>
