/* eslint no-console: 0 */
import * as types from '../../mutation-types';
import CampaignsBroadcast from '../../../api/broadcast/campaignsBroadcast';

const state = {
  isLoading: false,
  isDownloading: false,
  campaigns: {
    data: [],
    meta: {},
  },
  engagement: {},
};

const getters = {
  getCampaigns(_state) {
    return _state.campaigns;
  },
  getCampaignsEngagement(_state) {
    return _state.engagement;
  },
  uIFlags(_state) {
    return _state.isLoading;
  },
  isDownloading(_state) {
    return _state.isDownloading;
  },
};

export const actions = {
  fetchCampaigns({ commit }, pramsObj) {
    commit(types.default.TOGGLE_BROADCAST_CAMPAIGNS, true);
    CampaignsBroadcast.get(pramsObj).then(data => {
      commit(types.default.SET_BROADCAST_CAMPAIGN, data);
      commit(types.default.TOGGLE_BROADCAST_CAMPAIGNS, false);
    });
  },
  getCampaignsEngagement({ commit }, pramsObj) {
    commit(types.default.TOGGLE_BROADCAST_CAMPAIGNS, true);
    CampaignsBroadcast.getCampaignsEngagement(pramsObj).then(data => {
      commit(types.default.SET_BROADCAST_ENGAGEMENT_CAMPAIGN, data);
      commit(types.default.TOGGLE_BROADCAST_CAMPAIGNS, false);
    });
  },
  downloadEngagement({ commit }, pramsObj) {
    commit(types.default.TOGGLE_BROADCAST_CAMPAIGNS_DOWNLOAD, true);
    return CampaignsBroadcast.downloadEngagement(pramsObj).then(data => {
      commit(types.default.TOGGLE_BROADCAST_CAMPAIGNS_DOWNLOAD, false);
    });
  },
};

const mutations = {
  [types.default.SET_BROADCAST_CAMPAIGN](_state, { data }) {
    _state.campaigns.data = data.payload;
    _state.campaigns.meta = data.meta;
  },
  [types.default.SET_BROADCAST_ENGAGEMENT_CAMPAIGN](_state, { data }) {
    _state.engagement = { ...data };
  },
  [types.default.TOGGLE_BROADCAST_CAMPAIGNS](_state, flag) {
    _state.isLoading = flag;
  },
  [types.default.TOGGLE_BROADCAST_CAMPAIGNS_DOWNLOAD](_state, flag) {
    _state.isDownloading = flag;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
