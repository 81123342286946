<template>
  <div class="banners">
    <LimitBanner
      :exceeded-active-conversations="
        account.exceeded_active_conversations &&
        !hideWhenSuspended &&
        !account.spending_grace_macs
      "
    />
    <AllBanner
      v-if="
        account.exceeded_active_conversations &&
        account.spending_grace_macs &&
        (account.active_subscription &&
          account.active_subscription.subscription_type) !== 'grace'
      "
    />
    <RenewalBanner
      :end-date="
        account.active_subscription && account.active_subscription.end_date
      "
      :condition="
      !account.upcoming_subscription &&
        !account.exceeded_active_conversations &&
        !account.spending_grace_macs &&
        (account.active_subscription &&
          account.active_subscription.subscription_type) !== 'grace'
      "
    />
    <QuotaRenewalBanner
      :end-date="
        account.active_subscription && account.active_subscription.end_date
      "
      :condition="
      !account.upcoming_subscription &&
        account.exceeded_active_conversations &&
        account.spending_grace_macs &&
        (account.active_subscription &&
          account.active_subscription.subscription_type) !== 'grace'
      "
    />
    <AllBannerInGrace
      v-if="
        !account.exceeded_active_conversations &&
        !account.spending_grace_macs &&
        (account.active_subscription &&
          account.active_subscription.subscription_type) === 'grace'
      "
      :reminderDays="
        account.active_subscription &&
        account.active_subscription.grace_remaining_days
      "
    />

    <AllTrueInGrace
      v-if="
        account.exceeded_active_conversations &&
        account.spending_grace_macs &&
        (account.active_subscription &&
          account.active_subscription.subscription_type) === 'grace'
      "
      :reminderDays="
        account.active_subscription &&
        account.active_subscription.grace_remaining_days
      "
    />
    <SpendingBanner
      :spendingGraceMacs="
        !account.exceeded_active_conversations && account.spending_grace_macs
      "
      :limits="account.limits"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import AllBanner from './AllBanner.vue';
import AllBannerInGrace from './AllBannerInGrace.vue';
import AllTrueInGrace from './AllTrueInGrace.vue';
import LimitBanner from './LimitBanner.vue';
import QuotaRenewalBanner from './QuotaRenewalBanner.vue';
import RenewalBanner from './RenewalBanner.vue';
import SpendingBanner from './SpendingBanner.vue';

export default {
  name: 'Banners',
  components: {
    LimitBanner,
    AllBanner,
    RenewalBanner,
    QuotaRenewalBanner,
    AllBannerInGrace,
    AllTrueInGrace,
    SpendingBanner,
  },
  computed: {
    ...mapGetters({
      getAccount: 'accounts/getAccount',
      currentAccountId: 'getCurrentAccountId',
    }),
    account() {
      return this.getAccount(this.currentAccountId);
    },
    hideWhenSuspended() {
      return this.$route.path.includes('suspended');
    },
  },
};
</script>
<style></style>
