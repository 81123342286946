// import ContactsView from './components/newContacts/ContactsView';
import { frontendURL } from '../../../helper/URLHelper';

export const routes = [
  {
    path: frontendURL('accounts/:accountId/contacts'),
    name: 'contacts_dashboard',
    roles: ['administrator', 'agent'],
    component: () => import('./components/ContactsUpdateUI/ContactsView'),
    meta: {
      permission: 'show_contacts',
    },
  },
  {
    path: frontendURL('accounts/:accountId/contacts/custom_view/:id'),
    name: 'contacts_segments_dashboard',
    roles: ['administrator', 'agent'],
    component: () => import('./components/ContactsUpdateUI/ContactsView'),
    props: route => {
      return { segmentsId: route.params.id };
    },
    meta: {
      permission: 'show_contacts',
    },
  },
  {
    path: frontendURL('accounts/:accountId/labels/:label/contacts'),
    name: 'contacts_labels_dashboard',
    roles: ['administrator', 'agent'],
    component: () => import('./components/ContactsUpdateUI/ContactsView'),
    props: route => {
      return { label: route.params.label };
    },
    meta: {
      permission: 'show_contacts',
    },
  },
  {
    path: frontendURL('accounts/:accountId/contacts/import'),
    component: () => import('./components/ContactsUpdateUI/import/Index.vue'),
    meta: {
      permission: 'import_contacts',
    },
    children:[
      {
        path: '',
        redirect:'prepare'
      },
      {
        path: 'prepare',
        name: 'contacts_import_prepare',
        component: () => import('./components/ContactsUpdateUI/import/Prepare.vue'),
        meta: {
          permission: 'import_contacts',
        },
      },
      {
        path: 'mapping',
        name: 'contacts_import_mapping',
        component: () => import('./components/ContactsUpdateUI/import/Mapping.vue'),
        meta: {
          permission: 'import_contacts',
        },
      },
      {
        path: 'review',
        name: 'contacts_import_review',
        component: () => import('./components/ContactsUpdateUI/import/Review.vue'),
        meta: {
          permission: 'import_contacts',
        },
      },
    ]
  },
  {
    path: frontendURL('accounts/:accountId/contacts/:contactId'),
    name: 'contact_profile_dashboard',
    roles: ['administrator', 'agent'],
    component: () => import('./pages/NewContactManageView.vue'),
    props: route => {
      return { contactId: route.params.contactId };
    },
    meta: {
      permission: 'show_contacts',
    },
  },
];
