/* eslint no-console: 0 */
import * as types from '../../../mutation-types';
import FailedMessageList from '../../../../api/reports/message/failedMessageList';

const state = {
  isFetching: false,
  messages: {
    data: [],
    meta: {},
  },
  downlaod: {
    isLoading: false,
  },
};

const getters = {
  getMessageFailed(_state) {
    return _state.messages;
  },
  isLoading(_state) {
    return _state.isFetching;
  },
  getDownloaded(_state) {
    return _state.downlaod.isLoading;
  },
};

export const actions = {
  get: async function getMessageFailed({ commit }, prams) {
    commit(types.default.TOGGLE_MESSAGE_FAILED, { isFetching: true });
    try {
      const response = await FailedMessageList.get(prams);
      commit(types.default.SET_MESSAGE_FAILED, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.default.TOGGLE_MESSAGE_FAILED, { isFetching: false });
    }
  },
  download: async function downloadMessageFailed({ commit }, params) {
    commit(types.default.TOGGLE_DOWNLOAD_MESSAGE_FAILED, { isFetching: true });
    try {
      const response = await FailedMessageList.download(params);
      commit(types.default.SET_DOWNLOAD_MESSAGE_FAILED, response);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.default.TOGGLE_DOWNLOAD_MESSAGE_FAILED, {
        isFetching: false,
      });
    }
  },
};

const mutations = {
  [types.default.SET_MESSAGE_FAILED](_state, failedMessages) {
    _state.messages.data = failedMessages.payload;
    _state.messages.meta = failedMessages.meta;
  },
  [types.default.TOGGLE_MESSAGE_FAILED](_state, flag) {
    _state.isFetching = flag.isFetching;
  },

  [types.default.SET_DOWNLOAD_MESSAGE_FAILED](_state, downlaod, flag) {
    if (
      downlaod.status === Number(200) &&
      downlaod.statusText === String('OK')
    ) {
      _state.downlaod.isLoading = flag.isFetching;
    }
  },
  [types.default.TOGGLE_DOWNLOAD_MESSAGE_FAILED](_state, flag) {
    _state.downlaod.isLoading = flag.isFetching;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
