import ApiClient from './ApiClient';

class Agents extends ApiClient {
  constructor() {
    super('agents', { accountScoped: true });
  }

  getAgentsWithPagination(params) {
    return axios.get(`${this.url}/index_with_teams_and_inboxes`, {
      params: {
        page: params.page,
        search: params.search
      },
    });
  }
}

export default new Agents();
